<template>
	<div v-if="!loading">

 		<signupPopup v-if="typeof error === 'boolean'" :filteration="!error" :msg="'successfully registered'" @Try_again="try_again" />
		
	<div class="bg-white flex flex-col gap-4 md:w-5/6 w-4/5  shadow-lg shadow-black mx-auto max-h-fit  justify-between rounded-xl p-4">
		<div class="text-center text-3xl mb-4 font-bold text-gray">Regist<span class="text-darkred">ration</span></div>
		<div class="grid grid-cols-1 md:grid md:grid-cols-2">
		<BaseInput type="text" label="Fullname" v-model:modalValue="name" :filter="message?.name?.[0] || ''" class="w-full mx-auto border-b border-blue focus:bg-gr  focus:border-green  outline-none font-semibold text-darkred"/>
		<BaseInput type="text" label="Email" v-model:modalValue="email" :filter="message?.email?.[0] || ''" class="w-full mx-auto border-b border-blue  focus:border-green focus:bg-gr  outline-none font-semibold text-darkred"/>
		<BaseInput type="password" label="Password" v-model:modalValue="password" :filter="message?.password?.[0] || ''" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-darkred"/>

		<div class="m-4">
			<label class="text-blue text-md font-semibold">Work</label>
			<select v-model="work" class="font-semibold w-full outline-none overflow-scroll focus:bg-gr bg-white focus:border-green border-b border-gray p-2 text-darkred">
				<option class="p-2 border-b border-gray " value="Researcher">Researcher</option>
				<option class="p-2 border-b border-gray " value="Student">Student</option>
				<option class="p-2 border-b border-gray " value="Professor">Professor</option>
				<option class="p-2 border-b border-gray " value="Employee">Employee</option>
				<option class="p-2 border-b border-gray " value="Daily worker">Daily worker</option>
				<option class="p-2 border-b border-gray " value="Freelancer">Freelancer</option>
				<option class="p-2 border-b border-gray " value="Unemployed">Unemployed</option>
			</select>
		</div>

		<div class="m-4">
			<label class="text-blue text-md font-semibold">Specialization</label>
			<select v-model="id_specialization" class="w-full font-semibold focus:bg-gr outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-darkred">
				<option v-for="x in specialization" :key="x.id" :value="x.id" class="p-2 border-b border-gray " value="1">{{ x.name }}</option>
			</select>
		</div>

		<div class="m-4">
			<label class="text-blue text-md font-semibold">Country</label>
			<select v-model="country" class="w-full font-semibold focus:bg-gr outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-darkred">
				<option v-for="country in country_list" :key="country.id" :value="country">{{country}}</option>
				
			</select>
		</div>
		<div class="m-4">
			<label class="text-blue text-md font-semibold">Gender</label>
			<select v-model="Gender" class="font-semibold w-full outline-none overflow-scroll focus:bg-gr bg-white focus:border-green border-b border-gray p-2 text-darkred">
				<option class="p-2 border-b border-gray " value="Male">Male</option>
				<option class="p-2 border-b border-gray " value="Female">Female</option>
			</select>
		</div>

		</div>
		<div class="mx-auto mt-8 ">
			<input type="submit" value="Signup" class="rounded-md px-8 py-2 font-bold text-gray border-2 border-gray
			hover:text-darkred hover:bg-gray duration-500 cursor-pointer" @click="register()">
		</div>
	</div>
	</div>
	<Loading v-if="loading" />
</template>

<script>
import axios from 'axios'
import BaseInput from './../components/BaseInput.vue'
import signupPopup from './../components/signupPopup.vue'
import Loading from './Loading.vue'
	export default {
		components:{BaseInput,signupPopup,Loading},
		data() {
			return {
				country_list : ["Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla","Antigua &amp; Barbuda","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan",
				"Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia &amp; Herzegovina","Botswana","Brazil","British Virgin Islands",
				"Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D Ivoire",
				"Croatia","Cruise Ship","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia",
				"Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Gabon","Gambia","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland",
				"Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland",
				"Isle of Man","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein",
				"Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco","Mongolia","Montenegro",
				"Montserrat","Morocco","Mozambique","Namibia","Nepal","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Norway","Oman","Pakistan",
				"Palestine","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania","Russia","Rwanda","Saint Pierre &amp; Miquelon",
				"Samoa","San Marino","Satellite","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka",
				"St Kitts &amp; Nevis","St Lucia","St Vincent","St. Lucia","Sudan","Suriname","Swaziland","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand","Timor L'Este",
				"Togo","Tonga","Trinidad &amp; Tobago","Tunisia","Turkey","Turkmenistan","Turks &amp; Caicos","Uganda","Ukraine","United Arab Emirates","United Kingdom","Uruguay","Uzbekistan",
				"Venezuela","Vietnam","Virgin Islands (US)","Yemen","Zambia","Zimbabwe"],
				specialization:{},
				name:'',
				email :'',
				password:'',
				work:'',
				id_specialization:'',
				country:'',
				Gender:'',
				university:'',
				description:'',
				error:'',
				message:{},
				loading: false
			}
		},
		created(){
        this.get_specialization();

    	},
		methods: {
			try_again(x){
				this.error='';
			},
			async get_specialization(){
				try {
					const response=await axios.get('/get_specialization');
					this.specialization=response?.data?.data
				} catch (error) {
					console.log(error);
				}
			},
			async register(){
				
				try {
					this.loading = true;
					const response = await axios.post('/create_user',
					{
						name:this.name,
						email:this.email,
						password:this.password,
						work:this.work,
						id_specialization:this.id_specialization,
						country:this.country,
						gender:this.Gender,
					}).then((response) => {
						if(response.data.message == "Skip"){
							window.location.href = '/signup';
						}
						this.error=response.data.error;
						this.message=response.data.message	
						if (response.data.error == false) {
							localStorage.setItem('token', response?.data?.token);
							localStorage.setItem('role', response?.data?.role);
							window.location.href = '/';
						}
					});
				
				} catch (error) {
					console.log(error);				
				}finally {
				this.loading = false; 
				}
			},
		}
	}
</script>