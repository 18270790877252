<template>
	
	<div  id="hostSpecification" class="absolute  top-1/2 sm:top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/4 z-10 sm:w-2/3 w-5/6 h-fit bg-gray3 p-6 shadow-2xl rounded shadow-black">
		<!-- <signupPopup  v-if="true" class="z-10 mt-20" :filteration="!error"  :msg="''" @Try_again="try_again" /> -->
		<div class="flex flex-col justify-evenly">
			<div class="py-3 bg-darkblue text-center mb-6 text-xl  font-semibold rounded-lg text-green">Free hosts are the best</div>
			<div class="grid sm:gap-8 grid-cols-1 sm:grid-cols-2">
				
				<div class="flex flex-col sm:block">
					<label class="sm:font-semibold text-darkblue">Price<span class="text-xs text-darkred"> (Night)</span></label>
					<input type="text" v-model="price"  class="border-b border-green bg-gray3 text-green outline-none sm:ml-4">
					<p class="text-red text-xs text-center">{{message?.price?.[0]}}</p>
				</div>
	
				<div class="flex flex-col sm:block">
					<label class="sm:font-semibold text-darkblue">Guest N°</label>
					<input type="number" v-model="numGuest" name="" class="border-b border-green bg-gray3 text-green outline-none sm:ml-4">
					<p class="text-red text-xs text-center">{{message?.number_guests?.[0]}}</p>
				</div>
				<div class="flex flex-col sm:block">
					<label class="sm:font-semibold text-darkblue">Start Date</label>
					<input type="date" v-model="startDate" name="" class="border-b border-green bg-gray3 text-green outline-none sm:ml-4">
					<p class="text-red text-xs text-center">{{message?.start_date?.[0]}}</p>
				</div>
				<div class="flex flex-col sm:block">
					<label class="sm:font-semibold text-darkblue">Expire Date</label>
					<input type="date" v-model="expireDate" name="" class="border-b border-green bg-gray3 text-green outline-none sm:ml-4">
					<p class="text-red text-xs text-center">{{message?.expiry_date?.[0]}}</p>
				</div>
	
				<div class="flex flex-col sm:block">
					<label class="sm:font-semibold text-darkblue ">Description</label>
					<textarea  v-model="description" class="border p-2 border-darkblue mt-2 w-full h-28 sm:h-52 resize-none rounded sm:font-semibold text-green ">
				</textarea>
					<p class="text-red text-xs text-center">{{message?.description?.[0]}}</p>
				</div>
				
				<div>
					<label class="font-semibold text-darkblue ">Features</label>
					<div class="grid grid-cols-2 mt-4">
	
						<div v-for="index in specification" :key="index.id" >
							<input type="checkbox" v-model="select_Spec[index.id]" name="">
							<label class="text-darkblue text-sm ml-1 sm:ml-2">{{index.name}}</label>
						</div>
						
					</div>
				</div>
	
	
			</div>
	
				<div class="mx-auto w-3/4 flex flex-row justify-around mt-6">
					<div>
						<input type="submit" @click="submitForm()" value="Add" class="py-1 sm:py-2 px-4 sm:px-8 rounded-lg cursor-pointer outline-none border-2 border-darkblue sm:font-semibold text-darkblue hover:text-green hover:bg-darkblue transistion duration-300 ">
					</div>
					<div>
						<button class="py-1 sm:py-2 rounded-lg px-4 sm:px-8 outline-none border-2 border-darkblue sm:font-semibold text-darkblue hover:text-green hover:bg-darkblue transistion duration-300" @click="close">Cancel</button>
					</div>
	
				</div>

		</div>

		
	</div>
</template>
<script>
import signupPopup from './../signupPopup.vue'
import axios from 'axios'
	export default{
		components:{signupPopup},
		data() {
			return {
				specification:'',
				select_Spec:{},
				select_Spec_Ids: [],
				price:'',
				numGuest:'',
				startDate:'',
				expireDate:'',
				description:'',
				showSignUp:true,
				error:true,
				message:{},
			}
		},
		props:['Parameter'],
		created() {
			this.get_specification();
		},
		//msgsuccess
		methods:{
			close() {
                this.$parent.isOpen = false
            },
			try_again(x){
				this.error='';
			},
			async get_specification(){
				try {
					const response=await axios.get('/get_specification');
					this.specification=response.data.data;
				} catch (error) {
					console.log(error);
				}
			},
			async submitForm() {
				try {
					this.select_Spec_Ids = Object.keys(this.select_Spec).filter(id => this.select_Spec[id]);
					const response =await axios.post('/create_hosting',
					{
						price:this.price,
						start_date:this.startDate,
						expiry_date:this.expireDate,
						description:this.description,
						number_guests:this.numGuest,
						id_office:this.Parameter,
						id_specifications:this.select_Spec_Ids
					});
					if(response.data.error == false){
						this.$emit('msgsuccess' , 1);
						this.close();
					}
					else if(response.data.error == true){this.error=true;
						this.message = response.data.message}
					console.log(this.error)
					
				} catch (error) {
					console.log(error);				
				}
			},
		},

	}
</script>