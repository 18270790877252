<template>
    <div class="flex flex-col">
            
             <div class="h-28 w-28 mb-4 rounded-full mx-auto">
                <img  v-if="! info.image_url" src="./../../../assets/account.svg" class="h-full w-full">
                <img  v-if="info.image_url" :src="info.image_url"  class="h-full w-full rounded-full">
            </div>
             <div class="grid grid-cols-2 gap-4 w-full mx-auto">
                <div class="text-sm sm:text-xl font-bold py-1 text-blue">Name:<span class="fsm:ont-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.name }}</span></div>
                <div class="text-sm sm:text-xl font-bold py-1 text-blue">Email:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.email }}</span></div>
                <div class="text-sm sm:text-xl font-bold py-1 text-blue">Work:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.work }}</span></div>
                <div class="text-sm sm:text-xl font-bold py-1 text-blue">University:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.university }}</span></div>
                <!-- <div class="text-sm sm:text-xl font-bold py-1 text-blue">Specialization:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.specialization.name }}</span></div> -->
                <div v-if="info.language" class="text-sm sm:text-xl font-bold py-1 text-blue">Language:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.language }}</span></div>
                <div v-if="info.country" class="text-sm sm:text-xl font-bold py-1 text-blue">Country:<span class="sm:font-medium sm:text-lg text-sm  text-black sm:ml-4 ml-2">{{ info.country }}</span></div>
                <div class="text-sm sm:text-xl font-bold py-1 text-blue">Description:
                    <p class="sm:font-medium sm:text-lg text-sm  text-black  block  w-4/5 mx-auto ">
                        {{ info.description }}
                    </p>
                </div>
            </div> 
    </div>
</template>
<script>
import axios from 'axios'
export default{
    props:['user'],
    data() {
        return {
            default:'#######',
            info :{}
        }
    },
    created() {
        this.getuser()
        console.log(this.user)
    },
    methods:{
            async getuser(){
            try {
                const response=await axios.post('/show_informtion_user/'+this.user);
                this.info=response.data.data;  
                console.log(this.info.name)   
            } catch (error) {
                console.log(error);
            }
          },
            close() {
                this.$parent.isOpen = false
            },
            
        }
    
}
</script>