<template>

	<div class="z-1 w-5/6 mx-auto">
		<div class="grid grid-cols-1 content-center">
			<button class="text-center ml-10 mr-10 p-1 px-1 text-blue duration-500 md:text-sm lg:text-lg font-semibold border-red hover:text-red text-black hover:bg-gray3  bg-red border-2 rounded-xl" @click="isOpen = true ;">Click here to view the steps to create your hosting</button>
		</div>
		<help class="z-20" v-if="isOpen" @cclose="closeHelp()" />

		<div class="mt-6 mx-auto w-full grid grid-cols-4">

			<button id="newHosts" @click=" activeTab ='newHosts',setActive($event)" class=" transition duration-500 rounded-tl-md py-1 sm:py-2 sm:px-6 px-1 border-2 border-b-0 border-black  text-green bg-black sm:font-semibold ">New hosts</button>
			
        	<button id="availableHosts"   @click=" activeTab =  'availableHosts',setActive($event)" class=" transition duration-500 border-l-0   py-1 sm:py-2 sm:px-6 px-1 border-2 border-b-0  border-black sm:font-semibold">Available hosts</button>
			
        	<button id="freehosts"   @click=" activeTab =  'freeHosts',setActive($event)" class=" transition duration-500 border-l-0   py-1 sm:py-2 sm:px-6 px-1 border-2 border-b-0  border-black sm:font-semibold">free hosts</button>

			
			<button id="activeHosts" @click=" activeTab ='reservedHosts',setActive($event)" class="transition duration-500  py-1 sm:py-2 sm:px-6 px-1 border-2 border-b-0 border-l-0 border-black sm:font-semibold rounded-tr-md ">reserved hosts</button>
   		</div>
			<div class="w-full h-fit shadow-2xl shadow-black mx-auto border-t-2 border-black">
				<component :is="activeTab"/>
			</div>
	</div>
	

	
		

</template>
<script>
import reservedHosts from './../components/userHostings/reservedHosts.vue'
import freeHosts from './../components/userHostings/freeHosts.vue'
import availableHosts from './../components/userHostings/availableHosts.vue'
import help from './../components/userHostings/helpCreate.vue'
import newHosts from './../components/userHostings/newHosts.vue'
	export default {
		name:'MyHosting',
		components:{reservedHosts,freeHosts,availableHosts,newHosts,help},
		data() {
			return {
				activeTab:'newHosts',
				isOpen : false,
			}
		},
		methods:{
			closeHelp(){
				this.isOpen = false
			},
			setActive(event) {
				const id = event.target.id
				const clicked = document.getElementById(id)
				const availableHosts = document.getElementById("availableHosts")
				const newHosts = document.getElementById("newHosts")
				const freeHosts = document.getElementById("freehosts")
				// const History = document.getElementById("History")
				const activeHosts = document.getElementById("activeHosts")

				if(id == "activeHosts") {
					this.manageHighlits(clicked,[availableHosts,newHosts,freeHosts,History])
				}else if(id == "availableHosts") {
					this.manageHighlits(clicked,[activeHosts,newHosts,freeHosts,History])
				}else if (id == "newHosts") {
					this.manageHighlits(clicked,[activeHosts,availableHosts,freeHosts,History])
				}else if (id == "freehosts") {
					this.manageHighlits(clicked,[activeHosts,availableHosts,newHosts,History])
				}
				//else if(id == "History") {
				// 	this.manageHighlits(clicked,[activeHosts,availableHosts,newHosts,freeHosts])
				// }
			},
			manageHighlits(activeElement,arr) {
				activeElement.classList.add("text-green","bg-black")
				arr.forEach(function(ele){
						ele.classList.remove("text-green","bg-black")
					})
			}
		}
	}
</script>