import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import axios from 'axios'
import VueWriter from "vue-writer";

import vue3StarRatings from "vue3-star-ratings";
axios.defaults.baseURL = 'https://www.ziarh.com/api';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';


axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.data.token) {
        if (error.response.data.message == 'Token is missing') {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            window.location.href = '/catchAll';
        } else if (error.response.data.message == 'Invalid token') {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            window.location.href = 'login';
        }
    } else {
        return error.response
    }

});




createApp(App).use(VueWriter).component("vue3-star-ratings", vue3StarRatings).use(router).mount('#app')