<template>
<div  id="hostSpecification" class="absolute border-2 border-black top-1/2 sm:top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/4 z-10 sm:w-2/3 w-5/6 h-fit bg-gray3 p-6 shadow-2xl rounded shadow-black">
		<!-- <signupPopup  v-if="true" class="z-10 mt-20" :filteration="!error"  :msg="''" @Try_again="try_again" /> -->
		<div class="flex flex-col justify-evenly">
			<div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 1:</span> Choose your property type</div>
            <div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 2:</span> Add photos</div>
            <div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 3:</span> Specify the location, then press 'Add'</div>
            <div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 4:</span> Go to the 'Available hosts' section</div>
            <div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 5:</span> Select your property and press 'Activate'</div>
            <div class="py-3 bg-black text-center mb-6 text-x  font-semibold rounded-lg text-green"><span class="text-red text-xl">Step 6:</span> Fill in your hosting information and press 'Add'</div>
				<div class="mx-auto w-3/4 flex flex-row justify-around mt-6">
					<div>
						<button class="py-1 sm:py-2 rounded-lg px-4 sm:px-8 outline-none border-2 border-darkblue sm:font-semibold text-darkblue hover:text-green hover:bg-darkblue transistion duration-300" @click="close">Cancel</button>
					</div>
	
				</div>

		</div>

		
	</div>



</template>
<script>
	export default{
		methods:{
			close() {
				this.$emit('cclose')
			}
		}
	}

</script>
