<template>
	<div class=" mb-0 w-full h-full p-2 bg-blue mt-16 flex flex-col">
		<!---->
		<div class="flex sm:flex-row flex-col justify-between">
			<!--Left-->
			<div class="flex flex-col mb-6">
				<div class="md:text-3xl text-xl font-bold text-emb p-4">Let's Travel !</div>
				<div class="md:text-lg text-md font-semibold text-white px-4">Find us on any of these platforms </div>
				<div class="flex flex-row justify-between mt-4">
					<div class="h-12 w-12 mx-auto "><a href="#" class="md:font-semibold text-sm  text-white hover:text-emb"><img src="./../assets/github.png" class="bg-gray rounded-full p-2 hover:bg-emb">Github</a></div>
					<div class="h-12 w-12  mx-auto "><a href="#" class="md:font-semibold text-sm  text-white hover:text-emb"><img src="./../assets/twitter.png" class="bg-gray rounded-full p-2 hover:bg-emb">Twitter</a></div>
					<div class="h-12 w-12 mx-auto "><a href="#" class="md:font-semibold text-sm  text-white hover:text-emb"><img src="./../assets/facebook.png" class="bg-gray rounded-full p-2 hover:bg-emb">Facebook</a></div>
	
				</div>
			</div>
			<!---->
			<!--Right-->
			<div class="grid grid-cols-2 ">
				<div class="flex flex-col p-4 md:p-8">
					<div class="md:text-xl text-md font-semibold text-emb mb-2">USEFUL LINKS</div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">About us</a></div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">Blog</a></div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">Github</a></div>
					
				</div>


				<div class="flex flex-col p-4 md:p-8">
					<div class="md:text-xl text-md font-semibold text-emb mb-2">OTHER RESOURSES</div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">Terms&Conditions</a></div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">Privacy Policy</a></div>
					<div class="text-white"><a href="#" class=" hover:text-black hover:font-semibold">Contact us</a></div>
					
				</div>
				
			</div>
			<!---->
		</div>
		<!---->

		<!---->
		<div></div>
		<!---->
		
	</div>
</template>
<script>
	export default {
		name:'Footer'
	}
</script>