<template>
    <div class="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-3/4 sm:w-5/6 h-5/6 bg-white shadow-2xl rounded shadow-black sm:p-4 p-2 overflow-y-scroll">
        <div class="w-3/4 mx-auto flex flex-row">
            <button id="Informations"  @click=" activeTab ='Informations',setActive($event)" class="w-full transition duration-500 rounded-tl-md py-1 sm:py-2 sm:px-6 px-1 border-2  border-black  text-green bg-black sm:font-semibold">Informations</button>
            <button id="Hosts"   @click=" activeTab =  'Hosts',setActive($event)" class="w-full transition duration-500 border-l-0   py-1 sm:py-2 sm:px-6 px-1 border-2 rounded-tr  border-black sm:font-semibold">Hosts</button>
        </div>
        <div class="w-5/6 mx-auto mt-4">

            <component :is="activeTab" :user="id"/> 
        </div>
        
        

        
            <div class="m-2"><button class="absolute m-4 right-0 bottom-0  px-8 py-2  text-blue border-2 border-darkblue sm:font-semibold text-base   rounded-lg hover:bg-darkblue hover:text-green transition duration-300" @click="close">Close</button></div>
        </div>
</template>
<script>
import Informations from './userDeatilsComponents/Informations.vue';
import Hosts from './userDeatilsComponents/Hosts.vue';
    export default {
        components:{Informations,Hosts},
        data() {
            return {
                //users:{},
                activeTab:'Informations'
            }
        },
        props:['id'],
        methods:{
            close() {
                this.$parent.isOpen = false
            },
            setActive(event){
                const id = event.target.id 
                const clicked = document.getElementById(id)
                const Informations = document.getElementById("Informations")
                const Hosts = document.getElementById("Hosts")
                if(id == "Informations"){
                    this.manageHighlits(clicked,[Hosts])
                }else if(id == "Hosts"){
                    this.manageHighlits(clicked,[Informations])

                }
            },
            manageHighlits(activeElement,arr) {
				activeElement.classList.add("text-green","bg-black")
				arr.forEach(function(ele){
						ele.classList.remove("text-green","bg-black")
					})
			}
            
        }
        
    }
</script>