<template>
    <Carousel>
      <Slide v-for="image in images" :key="image">
        <div class="carousel__item">
          <img :src="image.image">
        </div>
  
      </Slide>
  
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </template>
  
  <script>
  import 'vue3-carousel/dist/carousel.css'
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
  
  
  
  export default defineComponent({
    name: 'Basic',
    props:['images'],
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
  })
  </script>
  
  <style scoped>
  .carousel__item {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel__slide {
    padding: 2px;
  }
  
  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
  }
  </style>
  