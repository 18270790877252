<template>
    <div class="mt-12 mx-auto w-full border-b-2 border-darkblue">
        <!--<button id="requests" @click=" activeTab ='Requests',setActive($event)" class=" transition duration-500 rounded-tl-md sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-darkblue  text-green bg-darkblue font-medium text-sm sm:text-lg sm:font-semibold">users requets</button>-->
        <button id="active"   @click=" activeTab =  'Active',setActive($event)" class=" transition duration-500    sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 rounded-tl  border-darkblue text-green bg-darkblue  font-medium text-sm sm:text-lg sm:font-semibold">active users</button>
        <button id="blocked"  @click=" activeTab = 'Blocked',setActive($event)" class=" transition duration-500    sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-l-0 border-darkblue rounded-tr  font-medium text-sm sm:text-lg sm:font-semibold">blocked users</button>
    </div>
    <div class="w-full mt-4">
        <component :is="activeTab"/>
    </div>
</template>
<script>
    //import Requests from './requests.vue'    
    import Active from './active.vue'
    import Blocked from './blocked.vue'
    export default {
        name:'Users',
        components:{Active,Blocked},
        data() {
            return {
                activeTab:'Active',
            }
        },
        methods:{
         setActive(event)  {
            const id = event.target.id
            if(id == "blocked"){
                document.getElementById(id).classList.add("text-green","bg-darkblue")
                document.getElementById("active").classList.remove("text-green","bg-darkblue")
               // document.getElementById("blocked").classList.remove("text-green","bg-darkblue")
            }
            else if(id == "active"){
                document.getElementById(id).classList.add("text-green","bg-darkblue")
                //document.getElementById("requests").classList.remove("text-green","bg-darkblue")
                document.getElementById("blocked").classList.remove("text-green","bg-darkblue")
            }
            /*else{
                document.getElementById(id).classList.add("text-green","bg-darkblue")
                document.getElementById("requests").classList.remove("text-green","bg-darkblue")
                document.getElementById("active").classList.remove("text-green","bg-darkblue")
            }*/
         }
        },
    }
</script>