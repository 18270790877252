<template>
  <Carousel>
    <Slide v-for="image in images" :key="image">
      <div class="carousel__item">
        <img :src="image.url">
      </div>

    </Slide>

    <template #addons>
      <Navigation class="text-black sm:h-8 sm:w-8 transition duration-300 h-6 w-6  border-2 border-black border-black hover:bg-black hover:text-green rounded-full"/>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'



export default defineComponent({
  name: 'Basic',
  props:['images'],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
})
</script>

<style scoped>
.carousel__item {
  min-height: 90px;
  width: 65%;
  
}

.carousel__slide {
  padding: 10px;
}


</style>
