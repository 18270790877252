<template>
	<div class="w-5/6 shadow-2xl shadow-black mx-auto mt-4">
		<div class="font-bold w-full text-green text-center bg-darkblue rounded-t py-2">ANALYSIS</div>
		<div class="bg-white w-full h-fit grid grid-cols-1 sm:grid-cols-2 gap-4  p-4 md:p-8">




			<div class="w-full mx-auto shadow-md shadow-black flex flex-col">
        <div class="rounded-t w-full bg-gray font-bold text-green text-center  py-2">USERS</div>
        <div class="p-4 w-full bg-gray3">
          <canvas id="users" ></canvas>
        </div>
			</div>

      


      <div class="w-full mx-auto shadow-md shadow-black flex flex-col">
        <div class="rounded-t w-full bg-gray font-bold text-green text-center  py-2">HOSTS</div>
        <div class="p-4 w-full bg-gray3">
          <canvas id="hosts" ></canvas>
        </div>
      </div>



       <div class="w-full mx-auto shadow-md shadow-black flex flex-col">
        <div class="rounded-t w-full bg-gray font-bold text-green text-center  py-2">COUNTRIES</div>
        <div class="p-4 w-full bg-gray3">
          <canvas id="countries" ></canvas>
        </div>
      </div>



      <div class="w-full mx-auto shadow-md shadow-black flex flex-col">
        <div class="rounded-t w-full bg-gray font-bold text-green text-center  py-2">SPECIALITE</div>
        <div class="p-4 w-full bg-gray3">
          <canvas id="specialite" ></canvas>
        </div>
      </div>



      <div class="col-span-2 flex flex-col justify-around w-1/2 mx-auto my-6">
           <div class="text-center font-bold text-2xl ">ESTATES N°</div>

			     <div class="h-56 w-56 flex items-center justify-center  my-4 text-2xl text-darkred mx-auto
            border-4 border-darkblue rounded-full">
           <span class="font-bold">{{ countlocation }}</span> 

         </div>
      </div>

   
		</div>
  </div>
</template>
<script>
import axios from 'axios';
import Chart from 'chart.js/auto';
export default {
  data() {
    return {
      usersData : {id:"users",labels:null,values:null,colors:null},
      hostsData : {id:"hosts",labels:null,values:null,colors:null},
      countriesData : {id:"countries",labels:null,values:null,colors:null},
      specialiteData : {id:"specialite",labels:null,values:null,colors:null},
      countlocation :''
  
    }
  },


  async mounted() {



    //Request a data

    await axios.post('/analysis_user')
      .then(response => {
        this.fetchUsers(response.data)
        })
      .catch(error => {
        console.error(error);
      });
    await axios.post('/analysis_host')
      .then(response => {
        this.fetchHosts(response.data)
        })
      .catch(error => {
        console.error(error);
      });
    await axios.post('/analysis_specialty')
      .then(response => {
        this.fetchSpecialiteData(response.data)
        })
      .catch(error => {
        console.error(error);
      });//analysis_country
    await axios.post('/analysis_country')
      .then(response => {
        this.fetchcountryData(response.data)
        })
      .catch(error => {
        console.error(error);
      });//count_location
    
    //fetchSpecialiteData
    // await axios.post('/analysis_specialty')
    //   .then(response => {
    //     console.log(response)
    //     //this.fetchHosts(response.data)
    //     })
    //   .catch(error => {
    //     console.error(error);
    //   });

    // this.peiChart(countriesData)
    // this.peiChart(specialiteData)


  },
  async created() {
    await axios.post('/count_location')
      .then(response => {
        console.log(response)
        this.countlocation = response.data
        })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {

    fetchUsers(data){
      this.usersData.labels = data.labels
      this.usersData.values = data.values
      this.usersData.colors = this.generateHexColors(data.values.length)
      this.barChart(this.usersData)

    },
    fetchHosts(data){
      this.hostsData.labels = data.labels
      this.hostsData.values = data.values
      this.hostsData.colors = this.generateHexColors(data.values.length)
      this.barChart(this.hostsData)
    },
    fetchSpecialiteData(data){
      this.specialiteData.labels = data.labels
      this.specialiteData.values = data.values
      this.specialiteData.colors = this.generateHexColors(data.values.length)
      this.peiChart(this.specialiteData)
    },
    fetchcountryData(data){
      this.countriesData.labels = data.labels
      this.countriesData.values = data.values
      this.countriesData.colors = this.generateHexColors(data.values.length)
      this.peiChart(this.countriesData)
    },

  	peiChart(obj) {
      const ctx = document.getElementById(obj.id)
      const myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels:obj.labels ,
          datasets: [
            {
              data: obj.values,
              backgroundColor: obj.colors,
              hoverBackgroundColor: obj.colors
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
              legend: {
                labels: {
                  font: {
                    weight: 'bold', // Change the font weight here
                    size: 12 // Change the font size here
                  }
                }
              }
            }
          

        }

      });



  	},





    barChart(obj) {
      const ctx = document.getElementById(obj.id)
      const data = {
        labels: obj.labels,
        datasets: [{
          data: obj.values,
          backgroundColor:obj.colors,
          borderColor:obj.colors,
          borderWidth: 1
        }]
      };

      const config = {
            type: 'bar',
            data: data,
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    font: {
                      weight: 'bold', // Change the font weight here
                      size: 16 // Change the font size here
                    }
                  }
                },
                x: {
                  ticks: {
                    font: {
                      weight: 'bold', // Change the font weight here
                      size: 14 // Change the font size here
                    }
                  }
                }
                
              },
              plugins: {
                    legend: {
                      display: false // Hide the legend label and rectangle
                    }
                  }
            },
          };



      const myChart = new Chart(ctx,config);
    },
  generateHexColors(count) {
      var colors = [];

      for (var i = 0; i < count; i++) {
        // Generate random RGB values
        var r = Math.floor(Math.random() * 256);
        var g = Math.floor(Math.random() * 256);
        var b = Math.floor(Math.random() * 256);

        // Convert RGB to hexadecimal
        var hex = "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);

        colors.push(hex);
      }

      return colors;
    }

  }
};
</script>