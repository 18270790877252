<template>
	<div class="text-xl sm:text-3xl font-bold text-center py-2 bg-black text-white rounded-t w-3/4 mx-auto">Pick a <span class="text-pink">Country</span> and <span class="text-pink">Speciality</span>  !</div>
  <div class="w-3/4  py-10 flex flex-col sm:flex-row mx-auto mb-10 justify-center bg-white rounded-b shadow-md shadow-black">
    <div class="flex flex-row  mx-auto w-5/6">

      <select v-model="country" class="w-full border-2 rounded-l-full border-r-none font-semibold  outline-none overflow-scroll bg-white  border-black sm:p-1 text-darkred text-center">
      	<option value="" class="bg-gray2 font-bold text-black">No country</option>
        <option v-for="country in country_list" :key="country.id" :value="country">{{country}}</option>
        
      </select>

      <select v-model="speciality_id" class="w-full border-2  font-semibold  outline-none overflow-scroll bg-white  border-black sm:p-1 text-darkred text-center ">
      	<option value="" class="bg-gray2 font-bold text-black">No speciality</option>
      	<option v-for="speciality in specialization " :key="speciality.id" :value="speciality.id">{{speciality.name}}</option>
      </select>

      <input type="submit" @click="searchHostingBy()" value="Search" class="sm:font-bold sm:py-2 px-2 sm:px-4 border-l-none hover:bg-black transition duration-500 cursor-pointer hover:text-white border-2 border-black rounded-r-full">
    </div>
  </div>
	<div  class="grid grid-cols-1 md:flex md:flex-row justify-around h-1/2 mx-4">
		<div class="w-11/12 shadow-2xl shadow-black bg-gray2 p-2  mx-2 grid grid-cols-1 gap-2 sm:grid sm:grid-cols-4 justify-around h-screen overflow-y-scroll">
		<Card v-for="item in host" :key="item.id" :info="item"/>


		</div>
	</div>


	
</template>
<script>
/*			<router-link  v-for="card in cards" :key="card.id" :to="{name:'Details',params:{id:card.id}}">
			@click="showDetails(card.id)
*/


import axios from 'axios'
import Card from './../components/Card.vue'
import { isProxy, toRaw } from 'vue';

	export default {
		name:'Search',
		created(){
			this.searchHostingBy()
			this.get_specialization()
		},
		components:{Card},
		data() {
			return {
				cards:{},
				host:'',
				specialization:'',
				isOpen:false,
				speciality_id:null,
				country_list : ["Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla","Antigua &amp; Barbuda","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan",
				"Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia &amp; Herzegovina","Botswana","Brazil","British Virgin Islands",
				"Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D Ivoire",
				"Croatia","Cruise Ship","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia",
				"Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Gabon","Gambia","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland",
				"Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland",
				"Isle of Man","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein",
				"Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco","Mongolia","Montenegro",
				"Montserrat","Morocco","Mozambique","Namibia","Nepal","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Norway","Oman","Pakistan",
				"Palestine","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania","Russia","Rwanda","Saint Pierre &amp; Miquelon",
				"Samoa","San Marino","Satellite","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka",
				"St Kitts &amp; Nevis","St Lucia","St Vincent","St. Lucia","Sudan","Suriname","Swaziland","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand","Timor L'Este",
				"Togo","Tonga","Trinidad &amp; Tobago","Tunisia","Turkey","Turkmenistan","Turks &amp; Caicos","Uganda","Ukraine","United Arab Emirates","United Kingdom","Uruguay","Uzbekistan",
				"Venezuela","Vietnam","Virgin Islands (US)","Yemen","Zambia","Zimbabwe"],
				country:null,



			}
		},
		methods: {
			/*async search_hosting(){
				try {
					const response=await axios.get('/search_hosting');
					console.log(response.data)
					this.host = response.data
				} catch (error) {
					console.log(error);
				}
			},*/
			async get_specialization(){
				try {
					const response=await axios.get('/get_specialization');
					this.specialization=response.data.data;
				} catch (error) {
					console.log(error);
				}
			},
			async searchHostingBy(){
				try {			
					const response=await axios.get('/searchHostingBy', {
						params: {
							specializationId: this.speciality_id,
							country: this.country
						}
					});
					console.log(response)
					this.host = response.data
				} catch (error) {
					console.log(error);
				}
			},
			// showDetails(id) {
			// 	axios.get(`houses/${id}`)
			// 		.then((response)=>{
			// 			this.host = response.data
			// 			this.isOpen = true
			// 		})
			// 		.catch((err)=>{console.log(err)})
			// },
			// pageChange() {
			// 	// Whene change the page
			// }
		}
	}

</script>