<template>
	<div class="h-screen  overflow-y-scroll pb-6  justify-around">
		<div v-if="isnull" class="text-5xl select-none rounded font-semibold text-center text-white py-20 w-2/3 mx-auto  bg-black shadow-md shadow-black mt-28">You don't have available hosting</div>
		<signupPopup  v-if="success" class="z-10" :filteration="true" :Pprofile="true" :msg="'The data has been modified successfully'" @Try_again="try_again" />
		<div class="grid sm:grid-cols-3 grid-cols-1">
		<div v-for="location in locations" :key="location.id" class="flex flex-col shadow-md shadow-darkblue h-fit m-4 h-fit rounded-lg bg-white">
			<Carousel :images="location.images"/>
			<div class="text-center text-xl font-semibold text-darkblue">{{location.country || "#########"}}</div>
			<div class="text-center text-xl font-semibold text-darkblue">{{location.state || "#########"}}</div>
			<div class="text-center text-xl font-semibold text-darkblue">{{location.postcode  || "#########" }}</div>
			<vue3-star-ratings v-model="location.evaluation" starSize="15" step=1 controlBg="#000000" controlColor="#2dd4bf"  disableClick=true controlSize=0  />
			<span class="text-sm text-center font-bold text-blue">{{location.num_evaluation}}</span>
			<div class="flex flex-row justify-around m-2">
				<div><button class="sm:py-2 py-1 px-4 sm:px-6 border-2 rounded-lg transition duration-300 border-darkblue text-darkblue hover:bg-darkblue hover:text-green sm:font-semibold" @click="isOpen = true ; id=location.id">Activate</button></div>
				<div><input type="submit" value="Delete"  class="sm:py-2 py-1 px-4 sm:px-6 border-2 rounded-lg transition duration-300 border-red text-red hover:text-black hover:bg-red sm:font-semibold" @click="delete_location(location.id)" ></div>
			</div>
			<activateHost v-if="isOpen" :Parameter="(id)"  @msgsuccess="open(e)"/>
		</div>
	</div>
	</div>
</template>
<script>
import vue3starRatings from "vue3-star-ratings";
import signupPopup from './../signupPopup.vue'
import Carousel from './Carousel.vue'
import activateHost from './activateHost.vue'
import axios from 'axios'
	export default{
		name:'Available Hosts',
		components:{Carousel,activateHost,signupPopup,vue3starRatings},
		created() {
			this.load_location();
		},
		data() {
			return {
				id:'',
			isOpen:false,
			locations:{},
			success:false,
			rating:3.5,
			isnull:false
			}
		},
		methods:{
			close() {
                this.$parent.isOpen = false
            },
			open (){
				this.success = true;
			},
			async delete_location(id){
				try {
					const response=await axios.post('/delete_location/'+id);
					this.load_location();
				} catch (error) {
					console.log(error);
				}
			},
			async load_location(){
				try {
					const response=await axios.post('/officeUser');
					
					if(!response.data.offices || response.data.offices.length === 0){
						this.isnull = true;
					}else{
						this.locations=response.data.offices;
					}
				} catch (error) {
					console.log(error);
				}
			},
		},
	}
</script>