<template>
    <div class="mt-12 mx-auto w-full border-b-2 border-darkblue ">
        <button id="active" @click=" activeTab ='active',setActive($event)" class=" transition duration-500 rounded-tl-md sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-darkblue  text-green bg-darkblue font-medium text-sm sm:text-lg sm:font-semibold"> Active hosts</button>
        <button id="history"   @click=" activeTab =  'history',setActive($event)" class=" transition duration-500    sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-l-0 border-darkblue  font-medium text-sm sm:text-lg sm:font-semibold">Hosting history</button>
    </div>
    <div class="w-full mt-4">
        <component :is="activeTab"/>
    </div>
</template>
<script>
    import active from './active.vue'    
    import history from './history.vue'
    export default {
        name:'Users',
        components:{active,history},
        data() {
            return {
                activeTab:'active',
            }
        },
        methods:{
         setActive(event)  {
            const id = event.target.id
            if(id == "active"){
                document.getElementById(id).classList.add("text-green","bg-darkblue")
                document.getElementById("history").classList.remove("text-green","bg-darkblue")
            }
            else if(id == "history"){
                document.getElementById(id).classList.add("text-green","bg-darkblue")
                document.getElementById("active").classList.remove("text-green","bg-darkblue")
            }
            
         }
        },
    }
</script>