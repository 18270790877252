<template>
	<div class="w-1/3 mx-auto shadow-xl bg-white h-96 shadow-black">
		<div class="text-center font-semibold text-white py-2 rounded-t-lg text-2xl bg-darkblue">Email Verification</div>
		<div class="">
			<p class="text-center mt-6 text-darkrblue font-extrabold ">We have sent a code to your email </p>
			<p class="text-center text-red">{{ message.email[0]}}</p>
			<div class="flex flex-row justify-evenly mt-12">
				<input v-model="xadd [0]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
				<input v-model="xadd [1]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
				<input v-model="xadd [2]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
				<input v-model="xadd [3]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
				<input v-model="xadd [4]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
				<input v-model="xadd [5]" type="text" name="" maxlength="1" class="rounded-md text-center font-bold text-xl border-2 border-black outline-none h-10 w-10">
			</div>
			<!-- <p class="text-red font-semibold text-sm text-center mt-4">The number is wrong !</p> -->
			<div class="w-1/2 mx-auto mt-10"><button class="py-2 w-full border-2 rounded-lg border-darkblue text-darkblue font-semibold hover:bg-darkblue hover:text-green transition duration-500 " @click="Email_validate()">verify account</button></div>
			<div class="mt-4 mx-auto text-center text-sm">Didn't recive a code ?<button @click="Email_validate()" class="text-base ml-2 underline font-bold text-darkred">Resend</button></div>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
	export default {
		data() {
		return {
			xadd: Array(6).fill(''),
			message: {email:['']}
		};
	},
	created() {
		console.log(this.$route.params.email)
	},
	methods:{
		async Email_validate(id){
			try {
				var x=this.xadd [0]+this.xadd [1]+this.xadd [2]+this.xadd [3]+this.xadd [4]+this.xadd [5]
				const response=await axios.post('/Email_validate',{
						email:this.$route.params.email,
						otp:x
					});
				if(response.data.error == false){
					this.$router.push(`/signup/${this.$route.params.email}`);
				}else{
					this.message=response.data.message;
				}
			} catch (error) {
				console.log(error);
			}
		},
	}
	}
</script>