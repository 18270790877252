<template>
	<div class="w-5/6 mx-auto py-2 bg-darkblue rounded-t-md text-white font-semibold text-center">Reservation</div>
	<div class="w-5/6 mx-auto  flex flex-row bg-white shadow-lg shadow-black">
			<div class=" w-2/5 p-4 ">
			  	<Carousel class="">
				    <Slide v-for="image in images" :key="image">
				      <div class="carousel__item">
				        <img :src="image">
				      </div>

				    </Slide>

				    <template #addons>
				      <Navigation class="p-1 hover:text-green hover:bg-black rounded-full transition duration-300" />
				      <Pagination />
				    </template>
				 </Carousel>
				 <div class="w-1/4 mx-auto mt-10">
				 	<button class="py-2 px-6 transition duration-300 font-semibold text-darkblue border-2 rounded border-darkblue hover:bg-darkblue hover:text-green">Reserve</button>
				 </div>
			</div>


		<div class="grid grid-cols-3 gap-6 p-2 ">

		




		</div>
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

	export default {
		  components: {
			    Carousel,
			    Slide,
			    Pagination,
			    Navigation,
			 },
		data(){
			return {
				images:[
            'https://fastly.picsum.photos/id/13/2500/1667.jpg?hmac=SoX9UoHhN8HyklRA4A3vcCWJMVtiBXUg0W4ljWTor7s',
                'https://fastly.picsum.photos/id/12/2500/1667.jpg?hmac=Pe3284luVre9ZqNzv1jMFpLihFI6lwq7TPgMSsNXw2w',
                'https://fastly.picsum.photos/id/22/4434/3729.jpg?hmac=fjZdkSMZJNFgsoDh8Qo5zdA_nSGUAWvKLyyqmEt2xs0',
                'https://fastly.picsum.photos/id/29/4000/2670.jpg?hmac=rCbRAl24FzrSzwlR5tL-Aqzyu5tX_PA95VJtnUXegGU']
			}
		}
	}
</script>

<style scoped>
.carousel__item {
  width: 70%;

}


.carousel__prev,
.carousel__next {

}
</style>
