<template>
	<div class="flex flex-row h-screen justify-between w-5/6 bg-white mx-auto shadow-xl rounded-lg shadow-darkblue">
		<div class="w-1/4 h-full flex flex-col">
			<div class="py-2 mb-6 rounded-tl-lg w-full bg-darkblue text-center font-semibold text-green">Contacts</div>
			<!--Chats-->
			<div class="w-full flex flex-row  bg-blue  ">
				<div class="ml-2"><img src="https://media.istockphoto.com/id/1130884625/vector/user-member-vector-icon-for-ui-user-interface-or-profile-face-avatar-app-in-circle-design.jpg?s=612x612&w=0&k=20&c=1ky-gNHiS2iyLsUPQkxAtPBWH1BZt0PKBB1WBtxQJRE=" class="w-12 h-12 rounded-full"></div>
				<div class="font-semibold text-green py-4 ml-2">
					AHMED SIRADJ EDDINE
				</div>
			</div>


		</div>
		<div class="w-3/4 h-full border-l-2 border-black "></div>
	</div>
</template>
<script>
	export default {}
</script>