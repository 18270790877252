<template>
	<div class="w-2/3 mx-auto shadow-2xl shadow-black">
    	<div class="text-xl text-center font-bold text-white bg-darkblue py-2 rounded-t">History</div>
		<div  class="h-screen flex flex-col overflow-y-scroll ">
			<div v-for="item in Notification" :key="item.id">
				<div  v-if="item.state == 1" class="w-11/12 my-2 py-2 rounded mx-auto  shadow-black shadow-md bg-gray2 border-l-4 indent-4 rounded-l-none border-blue font-bold">
					You receive a request from  
					<span class="font-bold text-darkblue underline">
						{{ item.email }}
					</span>
				</div>
			</div>
			<div v-for="item in Notification" :key="item.id">
				<div  v-if="item.state == 2" class="w-11/12 my-2 py-2 rounded mx-auto  shadow-black shadow-md bg-brown border-l-4 indent-4 rounded-l-none border-green font-bold">
					Your request has been accepted by 
					<span class="font-bold text-darkblue underline">
						{{ item.email }}
					</span>
				</div>
			</div>
			<div v-for="item in Notification" :key="item.id">
				<div  v-if="item.state == 3" class="w-11/12 my-2 py-2 rounded mx-auto text-darkred shadow-black shadow-md bg-red2 border-l-4 indent-4 rounded-l-none border-darkred font-bold">
					Your request has been rejected by  
					<span class="font-bold text-darkblue underline">
						{{ item.email }}
					</span>
				</div>
			</div>
		
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
		return {
			Notification:''
		}
	},
	
	created() {
		this.Notification_history()
	},
	methods: {
		async Notification_history(){
            try {
				const response = await axios.post(`/Notification_history`)
				console.log(response)
				this.Notification = response.data
			} catch (error) {
				console.log(error);				
			}
        },
	},
}
</script>