<template>
	<div v-if="!loading" id="newHost" class=" w-full h-full flex flex-col">
		<signupPopup v-if="typeof error === 'boolean'" class="z-20" :filteration="!error" :msg="msg" @Try_again="try_again" />
			
			<div id="info" class="w-5/6 h-fit ml-6 grid grid-cols-1 justify-around mt-4 p-4">
				<div v-show="false">
					<div>
						<input v-model="Lat" name="lat"  id="lat" class=" ml-10 border-2 rounded bg-darkblue border-darkblue outline-none text-center text-green font-semibold" readonly>
					</div>
					<div>
						<input v-model="Lng" name="" id="lng" class=" ml-10 outline-none border-2 rounded bg-darkblue border-darkblue text-center text-green font-semibold" disabled>
					</div>
					<div>
						<input v-model="country" name="" id="location" class=" ml-10 text-center border-2 rounded bg-darkblue border-darkblue outline-none text-green font-semibold" readonly>
					</div>
					<div>
						<input v-model="state" name="" id="State" class=" ml-10 border-2 rounded bg-darkblue border-darkblue text-center outline-none text-green font-semibold" readonly>
					</div>
					<div>
						<input v-model="Postcode" name="" id="Postcode" class=" ml-10 text-center border-2 rounded bg-darkblue border-darkblue outline-none text-green font-semibold" readonly>
					</div>
				</div>
				<div>
					<label for="houseType" class=" font-semibold py-2  w-28 inline-block">House Type</label>
					<select id="mySelect" v-model="id_houseType" class=" text-center outline-none bg-darkblue py-1 rounded ml-10 px-6 text-green font-semibold">
						<option value="" selected disabled>
							Choose
						</option>
						<option v-for="index in houseType" :key="index.id" :value="index.id" class=" text-center border-2 rounded bg-darkblue border-darkblue  text-green border-b border-pink" >{{index.name}}</option>
					</select>
				</div>
				<div class="mt-4">

					<label for="images" class="font-semibold py-2 w-10 sm:w-16">Location Images</label>
					<input  name="" type="file" id="images" class=" sm:ml-6 text-center outline-none font-semibold" @change="addFiles" multiple>
				</div>


			</div>
			<div id="map" class="w-full h-96 z-10 lg:py-28 rounded-tr-lg"></div>
			<div class="mx-auto py-4">
				<button @click="upload()" class=" py-2 px-8 rounded-lg text-blue border-2 border-blue hover:text-green hover:bg-blue duration-300 transition ">Add</button>
			</div>
			
	</div>
	<Loading v-if="loading" />
</template>

<script>
import signupPopup from './../signupPopup.vue'
import axios from 'axios'
import L from 'leaflet'
import Loading from '../../views/Loading.vue'
	export default {
		components:{signupPopup,Loading},
		name:'addHost',
		data() {
			return {
				point:{lat: 38.995583773578616, lng: 3.2623649993881014},
				location:'',
				Lat:'',
				Lng:'',
				country:'',
				state:'',
				Postcode:'',
				images:[],
				houseType:{},
				id_houseType:'',
				imageFile: null,
      			imageUrl: null,
				error:'',
				msg:'',
				loading: false
			}
		},
		created() {
			this.load_houseType();
		},
		methods:{

			try_again(x){
				this.error='';
			},
			async load_houseType(){
				try {
					const response=await axios.get('/get_type_location');
					this.houseType=response.data.data;
				} catch (error) {
					console.log(error);
				}
			},
			addFiles(event) {
				const files = event.target.files;
				for (let i = 0; i < files.length; i++) {
					this.images.push(files[i]);
				}
			},
			async upload() {
				try {
					this.loading= true
					const formData = new FormData();
					for (let i = 0; i < this.images.length; i++) {
						formData.append('images[]', this.images[i]);
					}
					formData.append('latitude', document.getElementById("lat").value);
					formData.append('longitude', document.getElementById("lng").value);
					formData.append('country', document.getElementById("location").value);
					formData.append('state', document.getElementById("State").value);
					formData.append('postcode', document.getElementById("Postcode").value);
					formData.append('id_type_location', this.id_houseType);
					const token = localStorage.getItem('token');
					const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${token}` 
					}};
					const response = await axios.post('/office_create', formData, config);
					this.error=response.data.error
					this.msg = response.data.message
				}catch (error) {
					console.log(error);
				}finally {
				this.loading = false; 
				}
			},
		},
		mounted() {
   			var map = L.map('map',{
      			center:[38.995583773578616, 3.2623649993881014],
      			zoom:4
    		})

    		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    		var previousPoint = null;

    		var guidMarker = L.marker(this.point).addTo(map)
    		.bindPopup('<div class="text-darkblue font-semibold w-full">Click to add marker</div>')
    		.openPopup()

    		map.on("click", function(event) {
  				  var latlng = map.mouseEventToLatLng(event.originalEvent);
					guidMarker.remove()
  					document.getElementById("lat").value = latlng.lat
  					document.getElementById("lng").value = latlng.lng
					fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latlng.lat}&lon=${latlng.lng}&format=json`)
					    .then(response => response.json())
					    .then(j => {
					      document.getElementById("location").value = j.address.country
					      document.getElementById("Postcode").value = j.address.postcode || "Unknown"
					      document.getElementById("State").value = j.address.state || j.address.village || j.address.town
					})
					if(previousPoint !== null) {
						previousPoint.remove()
					}
  					previousPoint = L.marker(latlng,{draggable:true}).addTo(map)
  			})
    	}
    }

</script>

