<template>
	<div v-if="!loading" class="sm:w-2/3  mx-auto py-2 text-center font-semibold text-white bg-black rounded-t">Profile</div>
	<div v-if="!loading" class="sm:w-2/3  p-4 h-fit bg-white rounded-b shadow-lg shadow-black mx-auto flex flex-col justify-around sm:flex-row">
		<div class="w-1/4">
			<svg v-if="user.image_url == null" xmlns="http://www.w3.org/2000/svg" class="w-28 h-28" viewBox="0 96 960 960"><path d="M222 801q63-44 125-67.5T480 710q71 0 133.5 23.5T739 801q44-54 62.5-109T820 576q0-145-97.5-242.5T480 236q-145 0-242.5 97.5T140 576q0 61 19 116t63 109Zm257.814-195Q422 606 382.5 566.314q-39.5-39.686-39.5-97.5t39.686-97.314q39.686-39.5 97.5-39.5t97.314 39.686q39.5 39.686 39.5 97.5T577.314 566.5q-39.686 39.5-97.5 39.5Zm.654 370Q398 976 325 944.5q-73-31.5-127.5-86t-86-127.266Q80 658.468 80 575.734T111.5 420.5q31.5-72.5 86-127t127.266-86q72.766-31.5 155.5-31.5T635.5 207.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 155T848.5 731q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5ZM480 916q55 0 107.5-16T691 844q-51-36-104-55t-107-19q-54 0-107 19t-104 55q51 40 103.5 56T480 916Zm0-370q34 0 55.5-21.5T557 469q0-34-21.5-55.5T480 392q-34 0-55.5 21.5T403 469q0 34 21.5 55.5T480 546Zm0-77Zm0 374Z"/></svg>
			<img v-else class="rounded-full h-fit border border-black w-fit" :src="user.image_url">
			
		</div>

		<div class="grid sm:grid-cols-2 grid-cols-1 gap-2 ml-6">
			<div class="py-1 ">
				<p class="text-blue font-semibold ">Name:<span class="ml-1 text-black inline-block  text-base">{{ user.name }}</span></p>
			</div>

			<div class="py-1">
				<p class="text-blue font-semibold ">Email:<span class="ml-1 text-black inline-block  text-base">{{ user.email }}</span></p>
			</div>

			<div class="py-1">
				<p class="text-blue font-semibold ">University:<span class="ml-1 text-black inline-block  text-base">{{ user.university }}</span></p>
			</div>
			
			<div class="py-1">
				<p class="text-blue font-semibold ">Work:<span class="ml-1 text-black inline-block  text-base">{{ user.work }}</span></p>
			</div>
			<div class="py-1">
				<p class="text-blue font-semibold ">Language:<span class="ml-1 text-black inline-block  text-base">{{ user.language }}</span></p>
			</div>

			<div class="py-1">
				<p class="text-blue font-semibold ">Specialization:<span class="ml-1 text-black inline-block  text-base">{{ user.specialization.name }}</span></p>
			</div>
			<div class="py-1">
				<p class="text-blue font-semibold ">Country:<span class="ml-1 text-black inline-block  text-base">{{ user.country }}</span></p>
			</div>

			<div class="py-1 col-span-2">
				<div class="text-blue font-semibold">Description</div>
				<textarea class="h-48 overflow-y-scroll w-11/12 border-2 rounded-lg overflow-y-scroll resize-none">
					{{ user.description }}
				</textarea>
			</div>
			<div class="col-span-2 mt-6 mb-4 flex flex-row justify-around">
				<div>
					<button @click="accept_reservation()" class="px-6 py-1 transition duration-300 rounded-lg border-2 border-darkblue text-darkblue hover:text-green font-bold hover:bg-darkblue">Accept</button>
				</div>
				<div>
					<button @click="Refuse_reservation()" class="px-6 py-1 transition duration-300 rounded-lg border-2 border-red text-red hover:text-black font-bold hover:bg-red">Refuse</button>
				</div>
				<div>
					<button class="px-6 py-1 transition duration-300 rounded-lg border-2 border-black text-black hover:text-white font-bold hover:bg-black" @click="goToPreviousRoute">Cancel</button>
				</div>
			</div>
		</div>

	</div>
	<Loading v-if="loading" />
</template>
<script>
import Loading from './Loading.vue'
import axios from 'axios'
	export default {
		data() {
			return {
				user:{},
				loading: false
			}
		},
		components:{Loading},
		created() {
			this.showInformation();
		},
		methods:{//Refuse_reservation
			goToPreviousRoute() {
				this.$router.go(-1);
				},
         	async showInformation(){
             try {
                const response =await axios.post(`/show_information/${this.$route.params.id}`);
				this.user=response.data.data;
				} catch (error) {
					console.log(error);
				}     
        	},
			async Refuse_reservation(){
             	try {
					this.loading = true;
					const response =await axios.post(`/Refuse_reservation/${this.$route.params.id}/${this.$route.params.idHost}`);
					this.$router.go(-1);	
				}catch (error) {
					console.log(error);
				}finally {
					this.loading = false; 
				}     
        	},//accept_reservation
			async accept_reservation(){
             	try {
					this.loading = true;
                	const response =await axios.post(`/accept_reservation/${this.$route.params.id}/${this.$route.params.idHost}`);
					this.$router.go(-1);
				} catch (error) {
					console.log(error);
				}finally {
					this.loading = false; 
				}   
        	},
		}
	}
</script>