<template>
	<div class="flex flex-row justify-around mt-16 p-4">
		
	<div class="basis-2/3 ">
		<table class="mx-auto w-5/6">
			<thead>
				<tr>
					<th class="py-2 bg-black text-white font-semibold px-4 rounded-tl-md">#</th>
					<th class="py-2 bg-black text-white font-semibold px-4">Speciality</th>
					<th class="py-2 bg-black text-white font-semibold px-4 rounded-tr-md">Delete</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item ,index) in speciality" :key="item.index" class="text-center border-b-2 border-black py-2">
					<th>{{index+1}}</th>
					<th>{{item.name}}</th>
					<th>
						<button @click="delete_speciality(item.id)">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:bg-black hover:text-white rounded-full transition duration-300">
							  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
							</svg>
						</button>
					</th>
				</tr>
			</tbody>
		</table>
	</div>	



	<div class="basis-1/3">
		<div class="text-xl font-bold">New Speciality</div>
		<input v-model="new_speciality" type="text" placeholder="Write here" class="border-b-2 outline-none border-black bg-gray3">
		
			<button @click="craete_speciality()" class="ml-4">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 hover:text-white hover:bg-black rounded-full transition duration-300">
  					<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>

			</button>
			<p class="text-red text-xs text-center">{{message?.name?.[0]}}</p>
	</div>
	</div>
</template>
<script>
import axios from 'axios'
	export default {
		data() {
			return {
				speciality:{},
				new_speciality:'',
				message:{},
			}
		},
		created() {
			this.load_Speciality();
		},
		methods:{
			async load_Speciality(){
				try {
					const response=await axios.get('http://127.0.0.1:8000/api/get_specialization');
					this.speciality=response.data.data
				} catch (error) {
					console.log(error);
				}
			},
			async craete_speciality(){
				try {
					const response=await axios.post('http://127.0.0.1:8000/api/create_specialization',{
						name:this.new_speciality
					});
					this.message= response.data.message
					if(response.data.error == false){
						this.load_Speciality();
					}
				} catch (error) {
					console.log(error);
				}
			},//delete_speciality
			async delete_speciality(id){
				try {
					const response=await axios.post('http://127.0.0.1:8000/api/delete_specialization/'+id);
					if(response.data.error == false){
						this.load_Speciality();
					}
				} catch (error) {
					console.log(error);
				}
			},
		},
	}
</script>