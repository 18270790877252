<template>
	
	<div class="container mx-auto flex  flex-row justify-around mb-8 relative py-4 w-1/2 border-b border-gray">
		<div class="flex flex-row">
			<img src="../../assets/admin.png" class="w-10 h-10">
			<div class="font-semibold text-black text-lg pt-2 ml-2">{{ name }}</div>
		</div>
	<div >


      <nav class="hidden sm:flex sm:flex-row mx-auto mt-2">
        <div>
        <router-link :to="{name:'database'}" class=" px-6 py-2 text-blue font-semibold hover:text-green text-lg  ">Database</router-link>
        </div>
        <div>
        <router-link :to="{name:'adminProfile'}" class=" px-6 py-2 text-blue font-semibold hover:text-green text-lg  ">Profile</router-link>
        </div>
        <div>
        <router-link :to="{name:'analysis'}" class=" px-6 py-2 text-blue font-semibold hover:text-green text-lg  ">Analysis</router-link>
        </div>
        <div>
        <router-link :to="{name:'adminConfig'}" class=" px-6 py-2 text-blue font-semibold hover:text-green text-lg  ">Config</router-link>
        </div>

        <div>
            <button @click="logout()"  class=" px-4 ml-8 hover:bg-black transition duration-300 text-pink font-semibold rounded-md hover:text-green text-lg  ">Log Out</button>
        </div>
      </nav>

      <div >
          <div >
              <svg id="btn" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sm:hidden w-10 h-10 text-blue mt-1 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
          </div>
            <div id="1" class="hidden right-0 mt-2  shadow-2xl shadow-blue rounded-md flex flex-col  justify-between bg-white" >

                    <router-link :to="{name:'database'}"><div class ="py-1 font-semibold text-center  px-6 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Database</div></router-link>

                    <router-link :to="{name:'adminConfig'}"><div class ="py-1 font-semibold text-center  px-6 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Configuration</div></router-link>

                    <router-link :to="{name:'analysis'}"><div class ="py-1 font-semibold text-center  px-6 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Analysis</div></router-link>

                    <router-link :to="{name:'adminProfile'}"><div class ="py-1 font-semibold text-center  px-6 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Profile</div></router-link>

                    <div @click="logout()" class ="py-1 font-semibold text-center  px-6 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">LogOut</div>
                </div>
      </div>
    </div>



  </div>



</template>
<script>
import axios from 'axios'
	export default {
    data() {
			return {
				name : ''
			}
		},
        mounted(){
            document.addEventListener("click",function(event) {
                const menu = document.getElementById("1")
                const btn = document.getElementById("btn")
                if(event.target === btn && menu.classList.contains("hidden")) {
                      menu.classList.remove("hidden");
                      menu.classList.add("absolute");
                }else if(event.target === btn && menu.classList.contains("absolute")) {
                      menu.classList.remove("absolute");
                      menu.classList.add("hidden");
                }else if(event.target !== btn && menu.classList.contains("absolute")){
                    menu.classList.remove("absolute");
                    menu.classList.add("hidden");
                }



            })
        },
        created (){
          this.getName()
        },
        methods: {//logout
          async getName(){
            try {
              const response=await axios.post('/get_name_admin');
              this.name=response.data.data;
            } catch (error) {
              console.log(error);
            }
          },
         async logout(){
               try {
                const response=await axios.post('/logoutAdmin');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                window.location.href = '/';
              } catch (error) {
                console.log(error);
              }
            }
        }

	}
</script>