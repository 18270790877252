<template>
	<div class="m-6">
	<label :if="label" class="text-blue text-md font-semibold mb-2">{{label}}</label>
	<input :type="type" :value="modalValue" @input="$emit('update:modalValue',$event.target.value)" :class="class">
	<p v-if="filter !== undefined && filter !== ''" class="text-xs text-red mt-1">{{filter}}</p>
	</div>
</template>
<script>
	export default {
		props:['type','label','class','filter','modalValue'],
		emits:['update:modalValue']
	}
</script>