<template>      
  <div class="mt-2">
            <table class="table-fixed w-full ">
              <thead >
                <tr class="text-blue bg-gray2 ">
                  <th class="sm:p-2"><div>Owner</div></th>
                  <th class="sm:p-2"><div>Owner email</div></th>
                  <th class="sm:p-2"><div>Price</div></th>
                  <th class="sm:p-2"><div>More details</div></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in hostings" :key="item.id" class="transition hover:shadow-lg hover:shadow-black text-center border-b border-green">
                  <td class="sm:px-2 text-sm sm:text-base sm:font-medium ">{{ item.host.name }}</td>
                  <td class="sm:px-2 text-sm sm:text-base sm:font-medium truncate">{{ item.host.email }}</td>
                  <td class="sm:px-2 text-sm sm:text-base sm:font-medium">{{ item.price }} $</td>
                  <td class="sm:px-2 text-sm sm:text-base sm:font-medium flex flex-col sm:flex-row justify-around">
                    <div><button @click=" showDetails(item.id)" class="px-2 sm:px-6 py-1 border-0 hover:bg-blue transition duration-300 rounded-lg hover:text-green ">Show</button></div>
                    <div><button @click="deleteHost(item.id)" class="px-2 sm:px-6 py-1 text-red transition duration-300 rounded-lg hover:bg-red border-0  hover:text-blue ">Delete</button></div>
                    
                  </td>
                  
                </tr>
              </tbody>
            </table>
            <Details :id="details" v-if="isOpen"/>
        </div>
</template>
<script>
import axios from 'axios'
import Details from './hostsDetails.vue'
    export default {
        name:'history',
        components:{Details},
        data() {
            return {
              isOpen:false,
              details:null,
              hostings :{},
            }
        },
        created() {
          this.getHosting()
        },
        methods:{//deleteHost
          async deleteHost(id){
            try {
                const response=await axios.post('/AdminDelete_hosting/'+id);
                if(response.data.error == false){
                  this.getHosting();
                }    
            } catch (error) {
                console.log(error);
            }
          },
          async getHosting(){
            try {
                const response=await axios.post('/all_hosting_history');
                  this.hostings=response.data.data  
            } catch (error) {
                console.log(error);
            }
          },
            showDetails(data) {
                this.details = data
                this.isOpen = true
            }
        }

    }
</script>
