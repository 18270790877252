<template>
	<div class="shadow-lg shadow-black h-fit rounded-lg  bg-white  m-1 flex flex-col justify-between">
		<div class="h-48 rounded-t-lg">
			<img class="h-full w-full rounded-t-lg" :src="info.image">
		</div>
		<div class="p-2 text-center">
			<div class="font-bold text-lg text-blue">{{ info.price }} $</div>
			<div class="text-black text-lg font-medium">{{ info.country }}</div>
			<div class="text-darkred text-lg font-medium">{{ info.specialization }}</div>
			<div class="font-semibold text-lg text-green">{{ info.type_locations }}</div>
			<vue3-star-ratings v-model="rating" starSize="15" step=1 controlBg="#000000" controlColor="#2dd4bf"  disableClick=true controlSize=0  />
			<span class="text-sm font-bold text-center text-blue">{{num_rating}}</span>
			<div class="w-full mt-6 mb-4">
			<router-link :to="'search/details/'+ info.id" class="text-blue font-semibold hover:text-green py-1 px-4 border-2 border-blue duration-300 transition rounded-md hover:bg-blue ">Show details >> </router-link>  
			</div>
		</div>
	</div>

</template>
<script>
import vue3starRatings from "vue3-star-ratings";
	export default {
		name:'Card',
		components:{vue3starRatings},
		props:['info'],
		data() {
			return {
				rating:0,
				num_rating:0,
			}
		},
		created() {
			this.rating = this.info.evaluation
			this.num_rating = this.info.num_evaluation
		},
		mounted() {
			
		}
	}
</script>