<template>
	<div class="bg-white flex flex-col md:w-1/3 w-3/4 my-28 shadow-lg shadow-blue mx-auto  justify-between rounded-xl p-4">
		<signupPopup v-if="typeof error === 'boolean'" class="z-20 mt-20" :filteration="!error" :msg="msg" @Try_again="try_again" />
		<div class="text-center text-3xl mb-4 font-bold text-gray">ADMIN<span class="text-green"> LOGIN</span> </div>
		<div><BaseInput v-model:modalValue="email" type="text" label="email" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold mt-2 text-green"/></div>
		<div><BaseInput v-model:modalValue="password" type="password" label="Password" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold mt-2 text-green"/></div>
		<div class="mx-auto mt-8 ">
			<input @click="login()" type="submit" value="Login" class="rounded-md px-8 py-2 font-bold text-gray border-2 border-gray
			hover:text-green hover:bg-gray duration-500 cursor-pointer
			">
		</div>
	</div>
</template>
<script>
import axios from 'axios'
import signupPopup from './../../components/signupPopup.vue'
import BaseInput from './../../components/BaseInput.vue'
	export default {
		name:"login",
		components:{BaseInput,signupPopup},
		data() {
			return {
				password :'',
				email :'',
				error:'',
			}
		},
		methods: {
			try_again(x){
				this.error='';
			},
			async login(){
				try {
				const response = await axios.post('/loginAdmin', {
				email: this.email,
				password: this.password,
				}).then((response) => {
				if (response.data.error == false) {
					localStorage.setItem('token', response?.data?.authorisation.token);
					localStorage.setItem('role', response?.data?.authorisation.role);
					window.location.href = '/admin/analysis';
				}else{
					this.error=response.data.error
					this.email=''
					this.password =''
				}
				});
				
			} catch (error) {
				console.log(error);
			}
			},
		},
	}
</script>