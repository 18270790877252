<template>
	<div v-if="!loading" class="flex flex-col-reverse md:flex-row justify-around p-4">
		<signupPopup v-if="typeof error === 'boolean'" class="z-20" :filteration="!error" :msg="msg" @Try_again="try_again" />
		<div class="flex flex-col w-11/12 md:w-2/3 rounded bg-white shadow-lg shadow-blue mx-auto ">
			<div class="py-2 font-bold text-center text-green bg-black rounded-t-md">Details</div>
			<div class="w-3/4 mx-auto my-12"><Carousel :images="images"/></div>
			<div class="flex flex-col justify-around">
				<div class="text-gray grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto p-2">

					<div class="text-gray font-semibold ">Name:<span class="text-black text-base">{{ data.host.name }}</span> </div>	
					<div class="text-gray font-semibold ">Country:<span class="text-black text-base">{{ data.location.country }}</span></div>		
					<div class="text-gray font-semibold ">Price<span class="text-xs text-darkred"> (Night): </span><span class="text-black text-base">{{ data.price }} $</span> </div>						<div class="text-gray font-semibold ">Email:<span class="text-black text-base">{{ data.host.email }}</span> </div>	
					<div class="text-gray font-semibold ">State:<span class="text-black text-base">{{ data.location.state }}</span></div>		
					<div class="text-gray font-semibold ">Guest N°:<span class="text-black text-base">{{ data.number_guests }}</span></div>		
					<div class="text-gray font-semibold ">Work:<span class="text-black text-base">{{ data.host.work }}</span></div>		
					<div class="text-gray font-semibold ">Estat type:<span class="text-black text-base">{{ data.location.type_location.name }}</span></div>	
					<div class="text-gray font-semibold ">Start Date:<span class="text-black text-base">{{ data.start_date }}</span></div>		
					<div class="text-gray font-semibold ">Language:<span class="text-black text-base">{{ data.host.language }}</span></div>
					<div class="text-gray font-semibold ">Postal code:<span class="text-black text-base">{{ data.location.postcode }}</span></div>		
					<div class="text-gray font-semibold ">Expire Date:<span class="text-black text-base">{{ data.expiry_date }}</span></div>		
					<div class="text-gray font-semibold ">Evaluation N°:<span class="text-black text-base">{{ data.location.num_evaluation }}</span></div>		
					<div >
						<p class="text-blue font-semibold ">Features:<span class="ml-1  text-sm">
							<ul  class="list-disc h-56 w-full mt-4 list-inside overflow-y-scroll text-black rounded bg-gray3 p-6">
								<li v-for="item in data.host_specif" :key="item.id" >{{ item.specifications.name}}</li>
							</ul>
						</span></p>

					</div>		
					<div class="text-gray font-semibold  ">Description: <div class="w-full bg-gray3 rounded  h-56 overflow-y-scroll text-black text-base border-black p-6 mt-4">{{ data.description }}
						</div>
					</div>	
				
				

					
			</div>
			<div v-if="!my" class="mx-auto p-8">
				<button @click="reserve()" class="py-2 px-4 rounded text-darkblue border-2 border-darkblue transition duration-300 font-semibold hover:text-green hover:bg-darkblue">Reserve</button>
			</div>
		</div>

	</div>
	<div class="w-5/6 h-fit mx-auto md:w-1/4 shadow-md shadow-black  mb-6 rounded">
		<div class="py-2 bg-black font-bold text-green w-full text-center rounded-t-md">Position</div>
		<Map class="z-20"  :lat="data.location.latitude" :lng="data.location.longitude" />
		<div v-if="!my" class="lg:p-4 p-2  bg-white border-t-4 border-black ">
					<vue3-star-ratings v-model="rating" starSize="25" step=1 controlBg="#000000" controlColor="#2dd4bf"   controlSize=25  />
		</div>
	</div>
		</div>
		<Loading v-if="loading" />
</template>
<script>
/*



*/
import vue3starRatings from "vue3-star-ratings";
import axios from 'axios'
import signupPopup from './../components/signupPopup.vue'
import Map from '../components/Map.vue'
import Carousel from './../components/Carousel.vue'
import Loading from './Loading.vue'
	export default {
		name:'Details',
		components:{Map,Carousel,vue3starRatings,signupPopup,Loading},
		data() {
			return {
				images:{},
				rating:0,
				data:{},
				h:true,
				error:'',
				msg:'',
				my:0,
				loading: false
			}

		},
		created(){
			this.loadDetails(this.$route.params.id)
			
		},


		methods:{
			try_again(x){
				this.error='';
			},
			async loadDetails(id) {
				const response = await axios.get(`/hosts_details/${id}`)
				.then((response)=>{
					if(response.data.error == false){
						this.data = response.data.data
						this.images = response.data.img
						this.my = response.data.you
					}
				})
				.catch(err =>{console.log(err)})
			},
			async reserve(){
				try {
					this.loading = true;
					if(localStorage.getItem('token') == null){
						window.location.href = '/enterEmail'
					}else{
						const formData = new FormData();
					formData.append('host', this.data.host.id);
					formData.append('id_hosting', this.data.id);
					formData.append('evaluation', this.rating);
					const token = localStorage.getItem('token');
					const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${token}` 
					}};
					const response=await axios.post('/reservation', formData, config);
					this.error=response.data.error
					this.msg=response.data.message
					
					}
				} catch (error) {
					console.log(error);
				}finally {
					this.loading = false; 
				}
			}

		}
	}
</script>