<template>
	<div class="w-4/5 mx-auto flex flex-col shadow-2xl shadow-blue rounded-lg bg-white">
		<signupPopup v-if="typeof error === 'boolean'" class="z-20" :filteration="!error" :msg="msg" @Try_again="try_again" />
		<div class="my-10 w-3/4 mx-auto">
		 <span class="block text-blue text-center sm:text-xl text-4xl font-extrabold">We thank you for providing your feedback</span>
		 <span class="block text-lg font-normal text-black mt-4 text-center">Welcome and thank you for supporting us. We know our website is still in its early testing phase and we would appreciate your feedback on our site, any issues you encountered, and suggestions for improvements or additions. The ZIARH team thanks you for your support</span>
		</div>
	</div>


	<div class="w-4/5 mt-8 mx-auto flex flex-col shadow-2xl shadow-blue rounded-lg bg-white">
		<div class="block text-blue text-center text-4xl font-extrabold my-4">Send a report!</div>
		<div class="mx-auto flex flex-col">
			<div class="mx-auto">
			<input v-model="email" type="text" placeholder="Write your email (optional)" class="lg:px-44 px-6 text-center py-2 outline-none border-2 border-gray bg-gray3 rounded-xl font-semibold" name="">
			</div>
			<div class="mx-auto mt-4">
			<textarea v-model="description" class=" text-black border-2 resize-none border-gray bg-gray3 rounded-xl  text-center lg:px-44 px-6 font-semibold py-28" placeholder="Write your report here!"></textarea>
			</div>
		</div>
		<div class="mx-auto my-4">
			<button @click="Send_report()" class="lg:px-12 px-6 py-2 border-2 border-gray rounded-xl font-bold hover:bg-gray hover:text-green transition duration-300">Send</button>
		</div>
	</div>
</template>
<script>
import signupPopup from './../components/signupPopup.vue'
import axios from 'axios'
	export default {
		name:'Support',
		components:{signupPopup},
		data(){
			return {
				email:null,
				description:'',
				error:'',
				msg:'',
			}
		},
		methods:{
			async Send_report(){
				try {
					const response=await axios.post('/create_report',
					{
						email : this.email,
						description:this.description,
					});
					console.log(response)
					this.error=response.data.error
					this.msg = response.data.message
				} catch (error) {
					console.log(error);
				}
			},
			try_again(x){
				this.error='';
			},
		},
	}
</script>