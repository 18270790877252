<template>
	<div class=" p-2 flex flex-row">
		<label class="text-blue text-md font-semibold mr-6">{{label}}</label>
		<div class="flex flex-col">
		<input :type="type" name="" v-bind:readonly="readonly" :value="value" class="w-5/6 border-b border-blue  focus:border-green  outline-none font-semibold text-darkred ">
		<p v-if="filter" class="text-xs text-red">{{filter}}</p>
		</div>
	</div>
</template>
<script>
export default {
	props:['label','value','filter','type','readonly'],
}
</script>