<template>
        <div class="mt-2">
            <table class="table-fixed w-full ">
              <thead class="rounded-lg-tl rounded-lg-tr">
                <tr class="text-blue bg-gray2 ">
                  <th class="sm:p-2"><div>Name</div></th>
                  <th class="sm:p-2"><div>Email</div></th>
                  <th class="sm:p-2 "><div>Country</div></th>
                  <th class="sm:p-2"><div>More details</div></th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="item in items" :key="item.id" class="text-center border-b hover:bg-green3 transition hover:shadow-lg hover:shadow-black  duration-300 border-green">
                 
                    <td class="px-2 sm:text-base text-sm  sm:font-medium">{{item.name}}</td>
                    <td class="px-2 sm:text-base text-sm  sm:font-medium truncate">{{item.email}}</td>
                    <td class="px-2 sm:text-base text-sm  sm:font-medium">{{item.work}}</td>
                  
                  <td class="px-2 sm:text-base text-sm sm:font-medium flex flex-col sm:flex-row justify-around">
                    <div><button @click=" showDetails(item.id)" class="px-6 py-1 border-0 hover:bg-blue transition duration-300 rounded-lg hover:text-green ">Show</button></div>
                    <div><button @click="Block(item.id)" class="px-6 py-1 text-green transition duration-300 rounded-lg hover:bg-green hover:text-blue font-semibold">block</button></div>
                    <div><button @click="deletUser(item.id)" class="px-6 py-1 text-red transition duration-300 rounded-lg hover:bg-red border-0  hover:text-blue font-semibold">Delete</button></div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
            <Details :id="idDetails" v-if="isOpen"/>
        </div>
</template>
<script>
import Details from './userDetails.vue'
import axios from 'axios'
    export default {
        name:'Requests',
        components:{Details},
        data() {
            return {
              isOpen:false,
              idDetails:'',
              items:{},
            }
        },
        created(){
          this.getuser();
        },
        methods:{//
          async deletUser(id){
            try {
                const response=await axios.post('/delete_user/'+id);
                if(response.data.error == false){
                  this.getuser();
                }       
            } catch (error) {
                console.log(error);
            }
          },
          async Block(id){
            try {
                const response=await axios.post('/block_user/'+id);
                if(response.data.error == false){
                  this.getuser();
                }       
            } catch (error) {
                console.log(error);
            }
          },
          async getuser(){
            try {
                const response=await axios.post('/index_users/1');
                this.items=response.data.data.data;           
            } catch (error) {
                console.log(error);
            }
          },

            showDetails(id) {
                this.idDetails = id
                this.isOpen = true
            }
        }

    }
</script>
