<template>
	<div class="h-screen flex flex-col bg-white overflow-y-scroll">
		<div v-if="isnull" class="text-5xl select-none rounded font-semibold text-center text-white py-20 w-2/3 mx-auto  bg-black shadow-md shadow-black mt-28">You don't have free hosting</div>
		<div v-for="item in information" :key="item.id" class="p-4 w-3/4 bg-1 mx-auto my-4 flex flex-col  rounded-lg shadow-xl shadow-black  ">
			<Carousel :images="item.image" class="w-1/2 mx-auto"/>
			<div class="grid grid-cols-2 gap-2  mx-auto mt-6">
				<Input :value="item.hosting.price" v-model="x"  label="Price" type="text" filter="" :readonly="reservation?true:false" />
				<Input :value="item.hosting.start_date" v-model="StartDate" label="StartDate" type="date"  filter=""/>
				<Input :value="item.hosting.number_guests" v-model="NumGuest" label="Guest N°" type="number" filter="" />
				<Input :value="item.hosting.expiry_date" v-model="ExpireDate" label="ExpireDate" type="date"  filter=""/>
				
				<div class="w-full m-4 col-span-2">
					<label class="text-blue text-md font-semibold h-72">Description</label>
					<textarea v-model="item.hosting.description" class="border p-4 border-blue w-full h-4/5 resize-none rounded 	font-semibold text-darkred ">
					</textarea>
				</div>
				
			</div>
			
			<div class="flex flex-col justify-around mt-4">
				<div class="mx-auto w-1/2 flex flex-row justify-between">
					
					
					
					<button @click="changes(item.hosting)" v-if="!reservation" class="rounded-md py-1 px-4 font-semibold text-gray border-2 border-gray
					hover:text-darkred hover:bg-gray duration-500 cursor-pointer">Save changes</button>
					<button @click="delete_hosting(item.hosting.id)" v-if="!reservation" class="py-1 px-4  font-semibold border-2 rounded-md border-red text-red hover:text-black transition duration-500 hover:bg-red">Delete</button>
					<button @click="showReservation(item.hosting.id)" class=" px-6 text-black border-2 py-1 hover:bg-black hover:text-white transition duration-300 font-semibold border-black rounded-md">show reservation</button>
				</div>
			</div>
		</div>
		<reservationsPopup :id="id_host" v-if="showRes"/>
		
	</div>
</template>
<script>
import Carousel from './../Carousel.vue'
import Input from './Input.vue'
import axios from 'axios'
import reservationsPopup from './reservationsPopup.vue'

export default{
	components:{Carousel,Input,reservationsPopup},
	data(){
		return {
			client:false,
			isOpen:true,
			showRes:false,
			information : {},
			x:33,
			id_host:'',
			isnull:false,
		}
	},
	created() {
		this.load_information();
	},
	methods:{
		async delete_hosting(id){
			try {
				const response=await axios.post('/delete_hosting/'+id);
				if(response.data.error == false){
						this.load_information();
				}
			} catch (error) {
				console.log(error);
			}
		},
		async load_information(){
			try {
				const response=await axios.post('/hosts_not_reserved');
				if(!response.data || response.data.length === 0){
						this.isnull = true;
					}else{
						this.information = response.data;
					}
			} catch (error) {
				console.log(error);
			}
		},
		async changes(hosting) {
			console.log(this.x)
				try {
					const response =await axios.post('/update_Hosting',
					{
						id : hosting.id,
						price:hosting.price,
						start_date:hosting.start_date,
						expiry_date:hosting.expiry_date,
						description:hosting.description,
						number_guests:hosting.number_guests,
						id_location :hosting.id_location,
					});
					if(response.data.error == false){
						this.load_information();
					}
					console.log(response)
				} catch (error) {
					console.log(error);				
				}
			},
			showReservation(id) {
			this.showRes = true
			this.id_host = id
			}
	},	
}

</script>