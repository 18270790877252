



<template>

 	<div id="map" class="  w-full h-screen py-48 rounded"></div>
</template>

<script>
import L from 'leaflet'
export default {
   name: 'Map',
   props:['lat','lng'],
   mounted() {
   	var map = L.map('map',{
      center:[this.lat,this.lng],
      zoom:6
    })
    L.tileLayer('http://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=PD8XJ66HVcmOugKVcgUz', {
    attribution: '<a href="http://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="http://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
	}).addTo(map)

	//add mark to the map
	L.marker([this.lat,this.lng]).addTo(map)
    .bindPopup(`<b>Location</b>`)
    .openPopup()

    // Get coordinates
   //  map.on("contextmenu", function (event) {
  	// console.log("Coordinates: " + event.latlng.toString());
  	// L.marker(event.latlng).addTo(map)}
}

}








	
</script>



























