<template>
	<div class="md:w-1/2 w-5/6  mx-auto mb-6 border-x-2 border-blue text-3xl text-blue font-bold shadow-2xl shadow-blue py-4 bg-white text-center ">
	Blogs</div>
	<div class="md:w-1/2 w-5/6 border-x-2 border-blue mx-auto shadow-2xl shadow-blue bg-white p-4">
		
	<div class="w-5/6  mx-auto flex flex-col justify-around ">

		<div class="md:p-4 p-2 border-b-2 mb-4 border-blue ">
			<div class="text-2xl text-blue font-semibold">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
			<div>
				<p class="font-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam</p>
			</div>
		</div>
		<div class="md:p-4 p-2 border-b-2 mb-4 border-blue ">
			<div class="text-2xl text-blue font-semibold">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
			<div>
				<p class="font-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam</p>
			</div>
		</div>
		<div class="md:p-4 p-2 border-b-2 mb-4 border-blue ">
			<div class="text-2xl text-blue font-semibold">Lorem ipsum dolor sit amet, consectetur adipiscing</div>
			<div>
				<p class="font-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dictum magna sed eros rutrum luctus. Donec arcu est, convallis ut augue non, molestie vulputate nisi. Vivamus a aliquam orci. Proin scelerisque sagittis magna et rutrum. Etiam ornare ac augue sit amet aliquam</p>
			</div>
		</div>
		
		

	</div>
	</div>
	
</template> 
<script>
//
//https://api.nytimes.com/svc/search/v2/articlesearch.json?q=election&api-key=deU81WZ3UunwgmdVh3ctXnEcZzSGOAd6

import axios from 'axios'
	export default {
		name:'Blog',
		created(){

axios.get('https://api.nytimes.com/svc/search/v2/articlesearch.json?api-key=deU81WZ3UunwgmdVh3ctXnEcZzSGOAd6')
				  .then(response => {
				    // Handle the response
				    console.log(response.data);
				  })
				  .catch(error => {
				    // Handle the error
				    console.error(error);
				  });

		   },

	}
</script>