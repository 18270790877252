<template>
    <div v-if="!loading" class="bg-white flex flex-col md:w-1/3 w-3/4 mt-12 mb-36 shadow-lg shadow-blue mx-auto  justify-between rounded-xl p-4">
		
		<div class="text-center text-3xl mb-4 font-bold text-green"><VueWriter :typeSpeed="100" :array="arr" /> </div>
		<p class="text-center text-red">{{ message.email[0]}}</p>
		<div><BaseInput v-model:modalValue="email" type="text" label="Email" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold mt-2 text-green"/></div>
		<router-link :to="{name:'login'}" class="text-red hover:text-green duration-200 ">I already have an account !!</router-link>
		<div class="mx-auto mt-8 ">
			<input @click="EmailToken()" type="submit" value="Send" class="rounded-md px-8 py-2 font-bold text-gray border-2 border-gray
			hover:text-green hover:bg-gray duration-500 cursor-pointer
			">
		</div>
	</div>
	<Loading v-if="loading" />
</template>

<script >
import axios from 'axios'
import VueWriter from "vue-writer";
import BaseInput from './../components/BaseInput.vue'
import Loading from'../views/Loading.vue'
export default {
    name:"EnterEmail",
    components:{BaseInput,VueWriter,Loading},
	data() {
      return { arr: ["Enter Your Email !"] , message: {email:['']} , email:'',loading: false};
    },
	methods:{
		async EmailToken(id){
			try {
				this.loading = true;
				const response=await axios.post('/EmailToken',{
						email:this.email,
					});
				if(response.data.error == false){
					this.$router.push(`/verification/${this.email}`);
				}else{
					this.message=response.data.message;
				}
			} catch (error) {
				console.log(error);
			}finally {
			this.loading = false; 
			}
		},
	}
}
</script>
