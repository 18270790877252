<template>
    <div class="mt-12 mx-auto w-4/5 border-b-2 border-black">
        <button id="speciality" @click=" activeTab ='speciality',setActive($event)" class=" transition duration-500 rounded-tl-md sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-black  text-green bg-black font-medium text-sm sm:text-lg sm:font-semibold">Specialities</button>
        <button id="features"   @click=" activeTab =  'features',setActive($event)" class=" transition duration-500    sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-l-0 border-black  font-medium text-sm sm:text-lg sm:font-semibold">Features</button>
        <button id="estates"  @click=" activeTab = 'estates',setActive($event)" class=" transition duration-500    sm:py-2 sm:px-6 px-1 py-1 border-2 border-b-0 border-l-0 border-black rounded-tr-md  font-medium text-sm sm:text-lg sm:font-semibold">Estates</button>
    </div>
    <div class="w-4/5 mb-10 shadow-lg shadow-black mx-auto h-screen overflow-y-scroll">
        <component :is="activeTab"/>
    </div>
</template>
<script>
import estates from './configComponents/estates.vue'
import features from './configComponents/features.vue'
import speciality from './configComponents/speciality.vue'
    export default {
        name:'',
        components:{estates,features,speciality},
        data() {
            return {
                activeTab:'speciality',
            }
        },
        methods:{
         setActive(event)  {
            const id = event.target.id
            if(id == "speciality"){
                document.getElementById(id).classList.add("text-green","bg-black")
                document.getElementById("features").classList.remove("text-green","bg-black")
                document.getElementById("estates").classList.remove("text-green","bg-black")
            }
            else if(id == "features"){
                document.getElementById(id).classList.add("text-green","bg-black")
                document.getElementById("speciality").classList.remove("text-green","bg-black")
                document.getElementById("estates").classList.remove("text-green","bg-black")
            }
            else{
                document.getElementById(id).classList.add("text-green","bg-black")
                document.getElementById("speciality").classList.remove("text-green","bg-black")
                document.getElementById("features").classList.remove("text-green","bg-black")
            }
         }
        },
    }
</script>