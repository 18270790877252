<template>
	<!--
	
 
  		<div class="mt-2 mr-10">
  			<div>
  				
  			<svg xmlns="http://www.w3.org/2000/svg" fill="#2dd4bf" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-6 text-green">
			  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
			</svg>
			<div class="absolute px-1 text-xs rounded-full text-white bg-red top-4 ml-6">1</div>
  			</div>
				<div class="absolute right-14 mt-2 bg-white shadow-2xl shadow-black">
                    <div class ="w-64 h-24 p-2 bg-white border-l-4 border-darkblue">
                    	<div class="text-red font-semibold text-xl text-center">Block user</div>
                    	<div>
                    		sdasdasdsadasdadasdasdsdsaadasdasdasdsadasdadsadsadasda
                    	</div>
                    	<div></div>
                    </div>
                    
                </div> 
  		</div>


	</div>
	-->
	<div class="flex flex-row mt-20">
		<div class="mx-auto">
			<div class="flex flex-row w-2/3 mx-auto">
				<div id="users" class="sm:text-3xl text-xl font-bold cursor-pointer border-b-2 border-solid p-2 border-green text-green transition duration-500   mx-auto w-full text-center" @click="activeTab = 'Users',setActive($event)">users</div>
				<div id="hosts" class="sm:text-3xl text-xl font-bold cursor-pointer border-b-2 border-solid p-2  mx-auto w-full text-center transition duration-500" @click="activeTab = 'Hostings',setActive($event)">hostings</div>
			</div>
			<div class="w-5/6 sm:w-4/5 h-screen mx-auto mt-4">
				<component :is="activeTab"/>
			</div>
		</div>
	</div>

</template>
<script>
import Users from './../../components/users/Users.vue'
import Hostings from './../../components/hostings/Hostings.vue'
	export default {
		name:'Admin',
		components:{Users,Hostings},
		data() {
			return {
				activeTab:"Users",
			}
		},
		methods:{
			setActive(event) {
				const id = event.target.id
				if(id == "users") {
					document.getElementById(id).classList.toggle("text-green","border-green")
					document.getElementById("hosts").classList.remove("text-green","border-green")
				}else if(id == "hosts") {
					document.getElementById("users").classList.remove("text-green","border-green")
					document.getElementById(id).classList.add("text-green","border-green")
				}
			},
		        showMenu(id) {
		      const menu = document.getElementById(id)
		      if(menu.classList.contains("hidden")) {
		          menu.classList.remove("hidden");
		          menu.classList.add("absolute");
		      }else {
		          menu.classList.remove("absolute");
		          menu.classList.add("hidden");
		      }
		    },
		    hideMenu(id) {
		      const menu = document.getElementById(id)
		          menu.classList.add("hidden");
		          menu.classList.remove("absolute");
		    }
		}
	}
</script>