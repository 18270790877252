<template>
	<div class="bg-white flex flex-col md:w-1/3 w-3/4 h-96 my-10 shadow-lg shadow-blue mx-auto  justify-between rounded-xl p-4">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-36 h-36 mt-8 text-green animate-spin mx-auto">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        <div class="text-center text-4xl mb-16 animate-bounce font-semibold">Loading ...</div>
	</div>
</template>
<script>
export default {
    name:"Loading",
}
</script>