<template>
    <div class="text-xl sm:text-3xl font-bold text-center py-2 bg-black text-white rounded-t w-3/4 mx-auto">Pick a <span class="text-pink">Country</span> !</div>
  <div class="w-3/4  py-10 flex flex-col sm:flex-row mx-auto mb-10 justify-center bg-white rounded-b shadow-md shadow-black">
    <div class="flex flex-row justify-between mx-auto w-5/6">
      <select v-model="country" class="w-full border-2 rounded-l-full border-r-none font-semibold  outline-none overflow-scroll bg-white  border-black sm:p-1 text-darkred text-center text-md sm:text-lg">
        <option v-for="country in country_list" :key="country.id" :value="country">{{country}}</option>
        
      </select>

      <input @click="SearchCountry()" type="submit" value="Search" class="sm:font-bold sm:py-2 px-2 sm:px-4 border-l-none hover:bg-black transition duration-500 cursor-pointer hover:text-white border-2 border-black rounded-r-full">
    </div>
  </div>

  <div class="mx-auto w-5/6 shadow-lg shadow-black bg-white border-2 border-black ">
    <div class="w-auto py-2 font-bold text-white text-center text-xl bg-black mx-auto">Map</div>
   	<div id="map" class="mx-auto   w-auto h-96  rounded z-30"></div>
  </div>

</template>

<script>
import L from 'leaflet'
import axios from 'axios'
export default {
   name: 'Map',
   houses:[],
   data() {
    return {
      country_list : ["Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla","Antigua &amp; Barbuda","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan",
        "Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia &amp; Herzegovina","Botswana","Brazil","British Virgin Islands",
        "Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D Ivoire",
        "Croatia","Cruise Ship","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia",
        "Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Gabon","Gambia","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland",
        "Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland",
        "Isle of Man","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein",
        "Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco","Mongolia","Montenegro",
        "Montserrat","Morocco","Mozambique","Namibia","Nepal","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Norway","Oman","Pakistan",
        "Palestine","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania","Russia","Rwanda","Saint Pierre &amp; Miquelon",
        "Samoa","San Marino","Satellite","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka",
        "St Kitts &amp; Nevis","St Lucia","St Vincent","St. Lucia","Sudan","Suriname","Swaziland","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand","Timor L'Este",
        "Togo","Tonga","Trinidad &amp; Tobago","Tunisia","Turkey","Turkmenistan","Turks &amp; Caicos","Uganda","Ukraine","United Arab Emirates","United Kingdom","Uruguay","Uzbekistan",
        "Venezuela","Vietnam","Virgin Islands (US)","Yemen","Zambia","Zimbabwe"],
        country:'',
        Map:'',
    }
   },


   
   mounted() {
   	var map = L.map('map',{
      center:[38.995583773578616,3.2623649993881014],
      zoom:4
    })
//<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>
    L.tileLayer('http://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=PD8XJ66HVcmOugKVcgUz', {
    attribution: ''
	}).addTo(map)
  this.Map = map
	//add mark to the map
	// L.marker([38.995583773578616,3.2623649993881014]).addTo(map)
 //    .bindPopup(`<b>Location</b>`)
 //    .openPopup()

          axios.get("/hosts_not_reserved").then((response)=>{
             response.data.location.forEach((point) =>{
          L.marker([point.location.latitude,point.location.longitude]).addTo(map)
            .bindPopup(`<a href='/search/details/${point.hosting.id}'>${point.hosting.price} $</a>`,{autoClose: false, autoPan: false})

            .openPopup()
        })
            })
// [{price:"1254",coordinates:[""]},]
    

    },
    methods:{
      SearchCountry(){
        //https://nominatim.openstreetmap.org/search?q=ALgerias&format=json&limit=1
        axios.get(`http://nominatim.openstreetmap.org/search?q=${this.country}&format=json&limit=1`).then((response)=>{
          const {lat,lon} = response.data[0]
          this.Map.setView([lat,lon],6)
        })
      }
    }


}

</script>