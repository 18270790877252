<template>
    <div class="p-2 top-1/2 left-1/2 fixed transform -translate-x-1/2 -translate-y-1/2 w-11/12  h-5/6  bg-white shadow-2xl rounded shadow-black overflow-y-scroll">
        <div class="flex flex-col-reverse  text-center">
            <div class=" p-8 grid grid-cols-3 gap-4">


            <div class="sm:text-lg text-blue text-left font-semibold">Name : <span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.host.name }}</span></div>
            <div class="sm:text-lg text-blue text-left font-semibold">Email :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.host.email }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Work :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.host.work }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Language :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.host.language }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Country :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.location.country }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Price :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.price }} $</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">State :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.location.state }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Guest N° :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.number_guests }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Estat type :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.location.type_location.name }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Start Date :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.start_date }}</span> </div>
            <div v-if="data.location.postcode == ''" class="sm:text-lg text-blue text-left font-semibold">Postal code :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.location.postcode }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Expire Date :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.expiry_date }}</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Evaluation N° :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">{{ data.location.num_evaluation }}</span> </div> 
            <div class="sm:text-lg text-blue text-left font-semibold">Description:<div class="w-full bg-gray3 rounded  h-56 overflow-y-scroll text-black text-base border-black p-6 mt-4">
                      {{ data.description }}  
                        </div> </div>


            <div >
                <p class="text-blue text-left font-semibold ">Features:<span class="ml-1  text-sm">
                     <ul  class="list-disc h-56 w-5/6 mt-4 list-inside overflow-y-scroll text-black rounded bg-gray3">
                                <li v-for="item in data.host_specif" :key="item.id" >
                                    {{ item.specifications.name}}
                                </li>
                    </ul>
                </span></p>

            </div>

            </div>
            <div class=" mx-auto w-1/3"><Carousel :images="images"/></div>
            
        </div>



<!-- <div class="sm:text-lg text-blue text-left font-semibold">Name : <span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">gdsfdsfsdfsdfs</span></div>
            <div class="sm:text-lg text-blue text-left font-semibold">Email :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">qsdfsdfsfsfs</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Work :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">dgsdfsdfsdfss</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Language :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">fsdfsdfsdcscdsds</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Country :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">fsdfsdfsdfqfdsfsfsdf</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Price :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">100000 $</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">State :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">gereterterte</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Guest N° :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">ytrhtgtrrtyrty</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Estat type :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">htrrttrtrht</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Start Date :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">jyjtyyryrege</span> </div>
            <div  class="sm:text-lg text-blue text-left font-semibold">Postal code :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">hrrgtrytrhtr</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Expire Date :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4"> sdsqdsqxqddsqdsq</span> </div>
            <div class="sm:text-lg text-blue text-left font-semibold">Evaluation N° :<span class="sm:font-medium sm:text-lg text-black ml-2 sm:ml-4">144</span> </div> -->







            <div class="w-1/5 mx-auto">
                <button class="px-6 mx-auto py-2 border-2 text-blue border-blue rounded hover:bg-blue hover:text-green  sm:font-semibold" @click="close">Close</button>
            </div>
    </div>
</template>
<script>
import Carousel from './../Carousel.vue'
import axios from 'axios'
    export default {
    	name:'Hosts details',
        components:{Carousel},
        props:['id'],
        data() {
            return {
                data :'',
                images:'',
            }
        },
        created(){
			this.loadDetails()
		},
        methods:{
            async loadDetails(){
                try {
					const response = await axios.post(`/admin_hosts_details/${this.id}`)
					 	this.data = response.data.data
					 	this.images = response.data.img
				} catch (error) {
					console.log(error);				
				}
            },
            close() {
                this.$parent.isOpen = false
            }
        }
        
    }
</script>