<template>
  <div class="container mx-auto mb-8 relative pt-4 flex flex-row justify-items-center justify-between border-b border-gray">

    <!--LOGO-->
    <div class=" mx-4 mb-2">
      <img src="../assets/LOGO.png" class="sm:w-22 sm:h-12 w-16 h-10">
    </div>
    <!---->


  <div >
      <nav class="hidden sm:flex sm:flex-row justify-between">
        <div>
        <router-link :to="{name:'home'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Home</router-link>
        </div>
        <div>
        <router-link :to="{name:'search'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Search</router-link>
        </div>
        <div>
        <router-link v-if="isAuth" :to="{name:'Hosting'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Hosting</router-link>
        </div>
        
        <div>
        <router-link :to="{name:'Support'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Support</router-link>
        </div>
       
        <div>
        <router-link v-if="isAuth" :to="{name:'profile'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Profile</router-link>
        </div>
        <div>
        <router-link v-if="! isAuth" :to="{name:'login'}" class=" px-6 text-blue  font-semibold hover:text-green text-lg  ">Login</router-link>
        </div>
        <div>
        <router-link v-if="! isAuth" :to="{name:'signup'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Signup</router-link>
        </div>
        <div>
        <router-link :to="{name:'Map'}" class=" px-6 text-blue font-semibold hover:text-green text-lg  ">Map
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-8 inline-block mb-1">
          <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
        </svg>


        </router-link>
        </div>
      </nav>
  
      <svg id="btn" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="sm:hidden w-8 h-8 text-blue sm:mr-4 mr-6 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>


    </div>

      <!--PROFILE isAuth && imageUrl -->
      <!--isAuth-->
      <div v-if="isAuth" class="z-40 w-fit h-fit">
          <div   class=" rounded-full z-10 flex flex-row">
            <div>
              
            <img id="profileIcon" v-if="isAuth && imageUrl" :src="imageUrl" class="border-gray w-12 mb-2 h-12 cursor-pointer rounded-full" >
			      <img id="profileIcon" v-else src="./../assets/account.svg" class="border-gray w-10 h-10 cursor-pointer rounded-full" />
            </div>

            <!--notification icon-->
            <div class="mt-2 ml-2 h-8 w-8" >
              <router-link :to="{name:'history'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-6 cursor-pointer text-blue"  >
                  <path id="notIcon" fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clip-rule="evenodd" />
                </svg>
              </router-link>
              <!-- <p class="text-xs text-darkblue rounded-full bg-green text-center font-bold h-4 w-4 absolute bottom-6 -right-2">1</p> -->
            </div>


          </div>
                <div id="1" class="hidden right-0 mt-2  shadow-2xl shadow-blue rounded-md flex flex-col  justify-between bg-white" >

                    <div>
                    <router-link :to="{name:'profile'}"><div class ="py-2 font-semibold text-center  px-14 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Profile</div></router-link>
                    <router-link :to="{name:'Hosting'}"><div class ="py-2 font-semibold text-center  px-14 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Hosting</div></router-link>
                    <input type="button" @click="logout()" value="Logout" class ="py-2 font-semibold text-center  px-14 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">
                    <!--<router-link :to="{name:'chat'}"><div class ="py-2 font-semibold text-center  px-14 text-black border-b-2 hover:text-green1 hover:border-green1 hover:bg-darkblue transition duration-300 border-darkblue">Chat</div></router-link>-->
                </div>
          

                </div>
      

                <!--notification menu 
                  <div id="notification" class="hidden right-0 mr-4 shadow-2xl shadow-blue rounded-md flex flex-col  justify-between bg-white" >
                    <div class ="w-56 p-2 border-b-2 border-darkblue">
                      <p class="text-green font-bold">Request Accept</p>
                      <p>fssqdsqdqsdqsdqsdqdsqq</p>
                    </div>
                    <div class ="w-56 p-2 border-b-2 border-darkblue">
                      <p class="text-red font-bold">Request Rejected</p>
                      <p>fssqdsqdqsdqsdqsdqdsqq</p>
                    </div>
                    
                    
                  </div>
                  
                -->
      



    </div>


  <div id="2" class="hidden shadow-xl z-30 shadow-black w-1/2 right-0 top-0  bg-white h-screen flex flex-col justify-around transition-6">
    <div class="flex flex-col text-center justify-between">

      <router-link :to="{name:'home'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Home</div>
      </router-link>

      <router-link v-if="!isAuth" :to="{name:'login'}">
        <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto   border-b border-green font-semibold text-lg py-2">Login</div>
      </router-link>

      <router-link v-if="isAuth" :to="{name:'profile'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Profile</div>
      </router-link>

      <router-link v-if="isAuth" :to="{name:'Hosting'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Hosting</div>
      </router-link>
      
      <router-link :to="{name:'Support'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Support</div>
      </router-link>
      
      <router-link v-if="!isAuth" :to="{name:'signup'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Sign Up</div>
      </router-link>

      <router-link :to="{name:'Map'}">
      <div class="cursor-pointer transition duration-300 hover:text-green hover:bg-gray w-3/4 mx-auto border-b border-green font-semibold text-lg py-2">Map</div>
      </router-link>
    
    </div>
    <!-- <div class=" mx-auto"><button @click="hideMenu(2)" class="mx-auto py-2 px-6 border-2 rounded-lg border-gray hover:text-green hover:bg-gray transition duration-300">Close</button></div>-->
  </div> 

</div>




  
</template>


<script>

import axios from 'axios'
export default {
  props:['isAuth'],
  data() {
			return {
        imageUrl:null,
      }
    },
    created() {
      if(this.isAuth){
        this.get_image_user()
      }
			
		},

  mounted() {
      const sideBar = document.getElementById(2)
      const btn = document.getElementById("btn")
      
    
    document.addEventListener('click',function(event){
      if(event.target == btn && sideBar.classList.contains("hidden")) {
          sideBar.classList.add("absolute");
          sideBar.classList.remove("hidden");
      }else if(event.target == btn && sideBar.classList.contains("absolute")){
          sideBar.classList.remove("absolute");
          sideBar.classList.add("hidden");
      }else if(event.target !== btn && sideBar.classList.contains("absolute")){
          sideBar.classList.remove("absolute");
          sideBar.classList.add("hidden");
      }else{
        //
      }
    })

    if(this.isAuth){
      

    document.addEventListener('click',function(event){
      const profileIcon = document.getElementById("profileIcon")
      const iconMenu = document.getElementById(1)
      if(event.target == profileIcon && iconMenu.classList.contains("hidden")){
        iconMenu.classList.add("absolute")
        iconMenu.classList.remove("hidden")
      }else if(event.target != profileIcon && iconMenu.classList.contains("absolute")){
        iconMenu.classList.add("hidden")
        iconMenu.classList.remove("absolute")
      }
    })
    /*
    document.addEventListener('click',function(event){
      const notificationIcon = document.getElementById("notIcon")
      const notificationMenu = document.getElementById("notification")
      if(event.target == notificationIcon && notificationMenu.classList.contains("hidden")) {
        notificationMenu.classList.replace("hidden","absolute")
      }else if(event.target != notificationIcon && notificationMenu.classList.contains("absolute")){
        
        notificationMenu.classList.replace("absolute","hidden")
      }
    })
    
    */
  }
  
  },



  methods: {//logout
    async logout(){
				try {
					const response=await axios.post('/logout');
          localStorage.removeItem('token');
          localStorage.removeItem('role');
					window.location.href = '/';
				} catch (error) {
					console.log(error);
				}
			},
    async get_image_user(){
				try {
					const response=await axios.post('/get_image_user');
					this.imageUrl=response.data.data;
				} catch (error) {
					console.log(error);
				}
			},

    hideMenu(id) {
      const menu = document.getElementById(id)
          menu.classList.add("hidden");
          menu.classList.remove("absolute");
    },
    showProfileMenu(){
      const profileIcon = document.getElementById(1)
      profileIcon.classList.remove("hidden")
      profileIcon.classList.add("absolute")
    },
    control(event) {
      const rightMenu = document.getElementById("2")
      if(event.target !== rightMenu && rightMenu.classList.contains("absolute")) {
          rightMenu.classList.remove("absolute");
          rightMenu.classList.add("hidden");
          document.removeEventListener('click',control)
      }
    }

  }
}
  
</script>

