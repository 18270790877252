<template>
  <navbar v-if="!isAuthenticated" :isAuth="false"/>
  <navbar v-else-if="isAuthenticated && !isAdmin " :isAuth="true"/>
  <adminNavbar v-if="isAuthenticated && isAdmin" />
  
  
  <router-view/>
  <Footer v-if="!isAdmin" />
</template>



<script> 
import navbar from './components/Navbar.vue'
import adminNavbar from './views/admin/navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  data() {
    return {
      isAuthenticated: false,
      isAdmin: false,
    }
  },
  components: {navbar,Footer,adminNavbar},
  computed: {
    isAuthenticated() {
      const token = localStorage.getItem('token');
      return !!token ;
    },
    isAdmin() {
      const role = localStorage.getItem('role'); 
      return role === 'admin' ?? false;
    },

  },
}
</script>


