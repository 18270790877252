<template>
	<div class="fixed	 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3  bg-white shadow-2xl rounded shadow-black rounded-tl-md rounded-tr-md">
		<div class="font-bold text-center bg-black py-2 text-white rounded-tl-md rounded-tr-md">Reservations</div>
        <div  class="w-full overflow-y-scroll h-96 flex flex-col p-4">
        	<router-link v-for="item in guests" :key="item.id" :to="'/clientprofile/' + item.id +'/'+id" class="my-1 flex flex-row border text-darkblue hover:text-green duration-500 transition rounded-lg border-black hover:bg-black  hover:shadow-black hover:shadow-md hover:cursor-pointer">
        		<div class="w-16 h-16 bg-black rounded-full m-1">
        			<img class="rounded-full  border" :src="item.image_url" >
        		</div>
        		<div class="font-bold text-xl text-inherit  py-5 mx-auto">
        			{{ item.name }}
        		</div>
        	</router-link>
			
			
        </div>
		<div class="my-1 mx-auto w-1/4">
			<button @click="close" class="py-2 mx-auto px-4 border-2 border-black rounded-md font-semibold hover:bg-black hover:text-green">Cancel</button>
		</div>
    </div>
</template>
<script>
import axios from 'axios';

	export default {
		props:['id'],
		data() {
			return {
				guests:''
			}
		},
		created() {
			this.Guest_requests();
		},
		methods:{
			close() {
				this.$parent.showRes = false
			},
			async Guest_requests(){
				const response = await axios.post('/Guest_requests/'+this.id)
				console.log(response.data.data[0].image_url)
				this.guests=response.data.data;
			}
		}
	}
</script>