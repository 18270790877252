<template>
	<div class="h-screen  overflow-y-scroll">
		<div v-if="isnull" class="text-5xl select-none rounded font-semibold text-center text-white py-20 w-2/3 mx-auto  bg-black shadow-md shadow-black mt-28">You don't have Reserved hosting</div>
		<div class="grid grid-cols-3">
			<div v-for="Host in Hosts" :key="Host.id" class="flex flex-col h-96 rounded-lg shadow-black shadow-xl p-2 bg-white m-4">
				<!-- image -->
				<div class="w-5/6 mx-auto">
					<Carousel  :images="Host.location.images"/>
				</div>
				<div class="flex flex-col mx-auto mt-4 text-center">
					<div class="text-blue font-bold">Email: <span class="text-darkred">{{ Host.guest.email }}</span></div>
					<div class="text-blue font-bold">Start date: <span class="text-darkred">{{ Host.start_date }}</span></div>
					<div class="text-blue font-bold">Expire date: <span class="text-darkred">{{ Host.expiry_date }}</span></div>
					<div class="text-blue font-bold">country: <span class="text-darkred">{{ Host.location.country }}</span></div>
				</div>
				<div class=" mx-auto mt-2">
					<button @click="openDetails()" class="py-2 mx-auto px-8 border-2 rounded-lg border-darkblue text-darkblue hover:text-green hover:bg-darkblue font-semibold transition duration-300">Show details >></button>
				</div>
			</div>
		</div>
		

	</div>
</template>
<script>
import axios from 'axios'
import Carousel from './carousel_reserved.vue'
export default{
	data() {
		return {
			isOpen:false,
			details :1,
			Hosts:'',
			images:'',
			isnull : false,
		}
	},
	components:{Carousel},
	created() {
		this.reservedHosts();
	},
	methods:{
		async reservedHosts(){
			try {
					const response = await axios.post(`/reservedHosts`)
					if(!response.data || response.data.length === 0){
						this.isnull = true;
					}else{
						this.Hosts= response.data
						this.images=response.data[0].location.images.image
					}
					
				} catch (error) {
					console.log(error);				
				}
		},

		openDetails() {
			this.isOpen = true;
		},

	},
}


</script>