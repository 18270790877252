<template>
	<div v-if="popup" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 bg-darkblue shadow-2xl rounded shadow-darkblue">
		<div >
			<svg v-if="filteration" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" 	stroke="currentColor" class="w-20 h-20 text-green mx-auto">
			  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
			</svg>
		
			
				<svg v-if="!filteration" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 mt-4 mb-2 rounded-full border-4 text-red mx-auto">
				  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
		</div>
		<div v-if="filteration" class="text-center text-green text-3xl">{{msg}}</div>
		<div v-if="!filteration" class="text-center mt-6 text-red text-3xl">An error occurred. Please try again <br>{{msg}}</div>

		<div v-if="filteration" class="flex flex-row justify-around mt-20">
			 
			 <div class="mx-auto ">
			 	<router-link to="/" type="button" class="border-2 border-green rounded-lg text-green  py-1 px-8 hover:bg-green hover:text-darkblue font-semibold transition duration-300">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-1 inline-block">
				  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
				</svg>Home</router-link>
			</div>
			<div v-if="filteration" class="mx-auto">
				<button @click="close" class="py-1 px-8 rounded-lg font-semibold transition duration-300 border-green border-2 text-green hover:bg-green font-semibold transition duration-300 hover:text-darkblue">
					<svg  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6	 inline-block rounded-full border border-green">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
					close</button>
				
			</div>
			
		</div> 

		<div v-if="!filteration" class="mx-auto w-fit mt-20 ">

			<button @click="$emit('Try_again',0)" class="py-2 px-10 rounded-lg font-semibold transition duration-300 border-red border-2 text-red hover:bg-red hover:text-darkblue">Try again</button>
		</div>


	</div>
</template>
<script>
	export default{
		props:['filteration','msg'],
		data() {
			return {
				popup:true
			}
		},

		methods:{
			close() {
				this.popup=false
				this.$parent.error = 'gg'
			}
		}


	}
//<router-link to="/createInvoice"

</script>


<style scoped>
	
</style>