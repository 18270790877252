<template>
<router-link :to="{name:'Support'}" class="mt-11 text-base text-blue flex items-center justify-center font-bold bg-gray2 py-2 w-5/6 mx-auto border-x-4 border-blue hover:bg-green1">Our website is in its testing phase. Please click here to leave your feedback ... موقعنا في مرحلته التجريبية . نرجو النقر هنا لترك ملاحظتكم</router-link>
<div v-if="user" class=" mt-12 grid grid-cols-2 content-center">
     	<router-link :to="{name:'search'}" class="text-center ml-10 mr-10 p-5 px-1 text-blue duration-500 md:text-xl lg:text-2xl font-semibold hover:bg-blue hover:text-green border-blue border-2 rounded-xl mt-6">Search hosting</router-link>
       <router-link :to="{name:'Hosting'}" class="text-center ml-10 mr-10 p-5 px-1 text-blue duration-500 md:text-xl lg:text-2xl font-semibold hover:bg-blue hover:text-green border-blue border-2 rounded-xl mt-6">Create my hosting</router-link>
	</div>
<div class="md:flex md:flex-row flex flex-col justify-items-center justify-around">
  <!--Title-->
  
  <div class="grid grid-cols-1 content-center">
    <div class="text-center mx-auto">
        <div><img src="./../assets/HEAD.png" class="w-44 h-28 inline-block"></div>
    	<p class="md:text-3xl text-xl  mb-1 text-darkblue font-bold">
         For Useful Travel

      </p>
    	<p class="md:text-3xl text-xl mb-1 text-darkblue font-semibold">For Easier Travel</p>
      <p class="md:text-3xl text-xl text-darkblue font-semibold">For Best Cost Travel</p>
    	<p class="md:text-3xl text-xl text-green font-semibold">And all for all...</p>

	</div>
	
  </div>
  <div class=" md:w-1/2 w-3/4 h-auto m-14">
    <div v-if="!user" class="bg-white flex flex-col md:w-2/3 w-3/4  shadow-lg shadow-blue mx-auto  justify-between rounded-xl p-4">
		  <signupPopup v-if="typeof error === 'boolean'" class="z-20 mt-20" :filteration="!error" :msg="msg" @Try_again="try_again" />
		  <div class="text-center text-3xl mb-4 font-bold text-gray">LOG <span class="text-green">IN</span> </div>
		  <div><BaseInput v-model:modalValue="email" type="text" label="Email" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold mt-2 text-green"/></div>
		  <div><BaseInput v-model:modalValue="password" type="password" label="Password" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold mt-2 text-green"/></div>
		  <router-link :to="{name:'signup'}" class="text-red hover:text-green duration-200 ">Create an account </router-link>
      <div class="mx-auto mt-8 ">
			  <input @click="login()" type="submit" value="Login" class="rounded-md px-8 py-2 font-bold text-gray border-2 border-gray
			  hover:text-green hover:bg-gray duration-500 cursor-pointer
			  ">
		  </div>
	  </div>
    <img v-if="user" src="../assets/hero.svg">
  </div>
</div>

<div class="mt-28 text-2xl text-blue text-center font-bold bg-gray2 py-2 w-5/6 mx-auto border-x-4 border-blue">NEWS</div>

<div class="md:grid md:grid-cols-3 gap-4 grid grid-cols-1 justify-between mt-12 w-5/6 mx-auto">
    <div class="rounded-xl cursor-pointer shadow-xl shadow-blue bg-white p-4 mx-2" v-for="n in news" :key="n" :if="n"
    @click="goTo(n.url)">
      <div class=""><img :src="n['urlToImage']"
       class="mx-auto h-1/2"></div>
        <div class="text-lg font-bold text-blue my-2">{{n.title}}</div>
        <div >{{n.description}}</div>
    </div>
    
</div>

</template>

<script>
import axios from 'axios'
import signupPopup from './../components/signupPopup.vue'
import BaseInput from './../components/BaseInput.vue'
export default {
  name: 'Home',
  components: {BaseInput,signupPopup},
  data() {
    return {
      news:{},
      password :'',
			email :'',
			error:'',
			msg:'',
      user:false,
    }
  },
  created() {
    var token = localStorage.getItem('token');
    if (token) {
      this.user=true
    }
    axios.get("https://newsapi.org/v2/everything?q=technology&apiKey=c3bcdd4251434dc299203197624cdb38")
          .then((response)=>{
            this.news = response.data.articles.slice(0,6)
          })
          .catch(err =>{console.error(err)})
  } ,
  methods:{
    try_again(x){
				this.error='';
			},
    async login(){
				
				try {
				const response = await axios.post('/login', {
				email: this.email,
				password: this.password,
				}).then((response) => {
				if (response.data.error == false) {
					localStorage.setItem('token', response?.data?.authorisation.token);
					localStorage.setItem('role', response?.data?.authorisation.role);
					window.location.href = '/';
				}else{
					this.error=response.data.error
					this.email=''
					this.password =''
				}
				});
			} catch (error) {
				console.log(error);
			}
		},
    goTo(link) {
      window.location.href = link
    }
  }
}
</script>
