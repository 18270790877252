
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import EnterEmail from './../views/EnterEmail.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Support from '../views/Support.vue'
import Search from '../views/Search.vue'
import Details from '../views/Details.vue'
import Profile from '../views/Informations.vue'
import NotFound from '../views/NotFound.vue'
import Blog from '../views/Blog.vue'
import Map from './../views/globalMap.vue'
import Chat from './../views/Chat.vue'
import Reserve from './../views/Reserve.vue'
import ClientProfile from './../views/ClientProfile.vue'
import History from './../views/History.vue'
import VerificationCode from './../views/VerificationCode.vue'
import Loading from './../views/Loading.vue'
import Hosting from './../views/Hosting.vue'

/*--- Admin compenets ---*/
import database from '../views/admin/database.vue'
import adminLogin from '../views/admin/login.vue'
import adminConfig from '../views/admin/config.vue'
import adminProfile from '../views/admin/profile.vue'
import analysis from '../views/admin/analysis.vue'
/*--------------------------*/

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
    {
    path: '/reserve',
    name: 'reserve',
    component: Reserve
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading
  },
  {
    path: '/clientprofile/:id/:idHost',
    name: 'clientprofile',
    component: ClientProfile
  },
  {
    path: '/verification/:email',
    name: 'verification',
    component: VerificationCode
  },
  {
    path: '/enterEmail',
    name: 'EnterEmail',
    component: EnterEmail
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  
  {
    path: '/map',
    name: 'Map',
    component: Map 
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/search/details/:id',
    name: 'Details',
    component: Details , 
  },
  {
    path: '/history',
    name: 'history',
    component:History, 
    meta: { requiresAuth: true , role: 'user' } ,
  },
  {
    path: '/hosting',
    name: 'Hosting',
    component:Hosting, 
    meta: { requiresAuth: true , role: 'user' } ,
  },
  {
    path: '/profile',
    name: 'profile',
    component:Profile, 
    meta: { requiresAuth: true , role: 'user' } ,
    children: [
      {
        path:'chat',
        name:'chat',
        component:Chat , 
        meta: { requiresAuth: true , role: 'user' } ,
      }
    ]
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: adminLogin , 
  },
  {
    path:'/admin',
    name:'admin',
    meta: { requiresAuth: true ,role: 'admin' } , 
    children: [
      {
        path:'database',
        name:'database',
        component:database ,
        meta: { requiresAuth: true ,role: 'admin' } , 
      },
      {
        path:'analysis',
        name:'analysis',
        component:analysis ,
        meta: { requiresAuth: true ,role: 'admin' } , 
      },
      {
        path:'profile',
        name:'adminProfile',
        component:adminProfile,
        meta: { requiresAuth: true ,role: 'admin' } , 
      },
      {
        path:'config',
        name:'adminConfig',
        component:adminConfig ,
        meta: { requiresAuth: true ,role: 'admin' } , 
      },
    ]
  },
  {
    path:'/:catchAll(.*)',
    name:'NotFound',
    component:NotFound,
  }


]
let router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); 
  const role = localStorage.getItem('role'); 

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token && role === 'admin') {
      next('/adminLogin');
    } else if(!token){
      next('/login');
    }else if (token && to.meta.role !== role){
      next('/');
    }else if (token && to.meta.role === role){
      next();
    }
    } else {
    next();
  }
});


export default router;
