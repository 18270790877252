<template>

    <div class="flex flex-col mt-8 rounded-t">
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 rounded-t">
    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div class="rounded-t">
        <table class="min-w-full text-left text-sm font-light rounded-t">
          <thead class="border-b font-medium bg-black text-white rounded-t">
            <tr>
              <th  class="px-6 py-2">#</th>
              <th  class="px-6 py-2">Price</th>
              <th  class="px-6 py-2">country</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(host,index) in hosts" :key="host.id" class="border-b-2 hover:bg-gray2 cursor-pointer duration-300 transition">
              <td class="whitespace-nowrap px-6 py-2 font-medium">{{ index }}</td>
              <td class="whitespace-nowrap px-6 py-2 font-medium">{{ host.price }}</td>
              <td class="whitespace-nowrap px-6 py-2 font-medium">{{ host.location.country }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


</template>
<script>
import axios from 'axios'
    export default{
        data() {
          return {
            hosts:{}
          }
        },
        props:['user'],
        created() {
          console.log(this.user)
          this.gethost()
        },
        methods: {
          async gethost(){
            try {
                const response=await axios.post('/git_user_hosting/'+this.user); 
                   this.hosts=  response.data.data
            } catch (error) {
                console.log(error);
            }
          },
        },
    }
</script>