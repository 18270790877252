<template>
	<div class="w-5/6 mx-auto">
	<div class="mx-auto w-auto bg-white rounded-xl shadow-md shadow-blue flex flex-col justify-around m-6 p-4">

		 <signupPopup v-if="typeof error === 'boolean'" class="z-10" :filteration="!error" :Pprofile="true" :msg="'The data has been modified successfully'" @Try_again="try_again" />
		<div class="sm:h-48 sm:w-48 w-28 h-28 mt-4  mx-auto relative">


			<input type="file" @change="upload"  class="opacity-0 absolute inset-0 cursor-pointer">
			<div>
			<img v-if="imageUrl" :src="imageUrl" class="rounded-full h-fit border border-black w-fit">
			<img v-else src="./../assets/account.svg" class="w-full h-full  rounded-full" />
			</div>
		</div>
		<div>
		<div class="grid grid-cols-1 md:grid md:grid-cols-3 justify-around">

		<BaseInput type="text" label="Fullname" v-model:modalValue="name" :filter="message?.name?.[0] || ''" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>
		<BaseInput type="text" label="Email" v-model:modalValue="email" :filter="message?.email?.[0]" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>
		<BaseInput type="date" label="Birthday" v-model:modalValue="birthday" :filter="message?.date_birth?.[0]" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>

		<div class="m-4">
			<label class="text-blue text-md font-semibold">Work</label>
			<select v-model="work" class="font-semibold w-full outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-green"> 
				<option class="p-2 border-b border-gray " value="Researcher">Researcher</option>
				<option class="p-2 border-b border-gray " value="Student">Student</option>
				<option class="p-2 border-b border-gray " value="Professor">Professor</option>
				<option class="p-2 border-b border-gray " value="Employee">Employee</option>
				<option class="p-2 border-b border-gray " value="Daily worker">Daily worker</option>
				<option class="p-2 border-b border-gray " value="Freelancer">Freelancer</option>
				<option class="p-2 border-b border-gray " value="Unemployed">Unemployed</option>
			</select>
		</div>
		<div class="m-4">
			<label class="text-blue text-md font-semibold">Specialization</label>
			<select v-model="nameSpecialization" class="w-full font-semibold outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-green">
				<option v-for="index in specialization" :key="index.id" :value="index.id" class="p-2 border-b border-gray ">{{index.name}}</option>
			</select>
		</div>

		<div class="m-4">
			<label class="text-blue text-md font-semibold">Country</label>
			<select v-model="country" class="w-full font-semibold outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-green">
				<option v-for="country in country_list" :key="country.id" :value="country">{{country}}</option>
				
			</select>
		</div>
		<div class="m-4">
			<label class="text-blue text-md font-semibold">Languages</label>
			<select v-model="languages" class="w-full font-semibold outline-none overflow-scroll bg-white focus:border-green border-b border-gray p-2 text-green">
				<option v-for="lang in languages_list" :key="lang.id" :value="lang">{{lang}}</option>	
			</select>
		</div>
		<div class="m-4">
			<label class="text-blue text-md font-semibold">Gender</label>
			<select v-model="gender" class="font-semibold w-full outline-none overflow-scroll focus:bg-gr bg-white focus:border-green border-b border-gray p-2 text-darkred">
				<option class="p-2 border-b border-gray " value="Male">Male</option>
				<option class="p-2 border-b border-gray " value="Female">Female</option>
			</select>
		</div>
		<div class="m-4">
			<label class="text-blue text-md font-semibold ">Description</label>
			<textarea v-model="description" class="border p-2 border-blue w-full h-4/5 resize-none rounded mt-4 font-semibold text-green ">
			</textarea>
			<p class="text-xs text-red mt-1">{{message?.description?.[0]}}</p>
		</div>	
		<!-- <BaseInput type="password" label="Password" v-model:modalValue="password" :filter="message?.password?.[0]" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>			 -->
		</div>
		<div class="ml-8 mt-8 ">
			<input type="submit" value="Save changes"  class="rounded-md px-8 py-2 font-bold text-gray border-2 border-gray
			hover:text-green hover:bg-gray duration-500 cursor-pointer" @click="update_informtion()">
		</div>

		</div>
		</div>
	</div>
</template>
<script>
import BaseInput from './../components/BaseInput.vue'
import axios from 'axios'
import signupPopup from './../components/signupPopup.vue'
	export default {
		components:{BaseInput,signupPopup},
		data() {
			return {
				country_list : ["Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla","Antigua &amp; Barbuda",
				"Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan","Bahamas","Bahrain","Bangladesh","Barbados",
				"Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia &amp; Herzegovina","Botswana","Brazil",
				"British Virgin Islands","Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Cape Verde",
				"Cayman Islands","Chad","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D Ivoire","Croatia",
				"Cruise Ship","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador",
				"Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia","Falkland Islands","Faroe Islands","Fiji",
				"Finland","France","French Polynesia","French West Indies","Gabon","Gambia","Georgia","Germany","Ghana",
				"Gibraltar","Greece","Greenland","Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana",
				"Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Isle of Man",
				"Israel","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kuwait","Kyrgyz Republic","Laos",
				"Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia",
				"Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco",
				"Mongolia","Montenegro","Montserrat","Morocco","Mozambique","Namibia","Nepal","Netherlands","Netherlands Antilles",
				"New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Norway","Oman","Pakistan","Palestine","Panama",
				"Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania",
				"Russia","Rwanda","Saint Pierre &amp; Miquelon","Samoa","San Marino","Satellite","Saudi Arabia","Senegal","Serbia",
				"Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka",
				"St Kitts &amp; Nevis","St Lucia","St Vincent","St. Lucia","Sudan","Suriname","Swaziland","Sweden","Switzerland",
				"Syria","Taiwan","Tajikistan","Tanzania","Thailand","Timor L'Este","Togo","Tonga","Trinidad &amp; Tobago","Tunisia",
				"Turkey","Turkmenistan","Turks &amp; Caicos","Uganda","Ukraine","United Arab Emirates","United Kingdom","Uruguay",
				"Uzbekistan","Venezuela","Vietnam","Virgin Islands (US)","Yemen","Zambia","Zimbabwe"],

				languages_list:["Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Azerbaijani", "Basque", "Belarusian"
				, "Bengali", "Bosnian", "Bulgarian", "Burmese", "Catalan", "Cebuano", "Chichewa", "Chinese (Mandarin)", "Corsican"
				, "Croatian", "Czech", "Danish", "Dutch", "English", "Esperanto", "Estonian", "Filipino", "Finnish", "French"
				, "Frisian", "Galician", "Georgian", "German", "Greek", "Gujarati", "Haitian Creole", "Hausa", "Hawaiian", "Hebrew"
				, "Hindi", "Hmong", "Hungarian", "Icelandic", "Igbo", "Indonesian", "Irish", "Italian", "Japanese", "Javanese"
				, "Kannada", "Kazakh", "Khmer", "Kinyarwanda", "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian"
				, "Lithuanian", "Luxembourgish", "Macedonian", "Malagasy", "Malay", "Malayalam", "Maltese", "Maori", "Marathi"
				, "Mongolian", "Montenegrin", "Nepali", "Norwegian", "Odia (Oriya)", "Pashto", "Persian", "Polish", "Portuguese"
				, "Punjabi", "Romanian", "Russian", "Samoan", "Scots Gaelic", "Serbian", "Sesotho", "Shona", "Sindhi", "Sinhala"
				, "Slovak", "Slovenian", "Somali", "Spanish", "Sundanese", "Swahili", "Swedish", "Tajik", "Tamil", "Tatar"
				, "Telugu", "Thai", "Turkish", "Turkmen", "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Welsh", "Xhosa"
				, "Yiddish", "Yoruba", "Zulu"],
				name:'',
				email:'',
				password:'',
				work:'',
				nameSpecialization:'',
				specialization:'',
				country:'',
				gender:'',
				birthday:null,
				imageFile: null,
      			imageUrl: null,
				description:'',
				languages:'',
				message:{},
				error:'',
			}
		},
		created() {
			this.loadInformations();
			this.get_specialization();
		},
		methods:{
			try_again(x){
				this.error='';
			},
			upload(event){
				const file = event.target.files[0];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					this.imageFile = file;
					this.imageUrl = reader.result;
				};
			},
			async get_specialization(){
				try {
					const response=await axios.get('/get_specialization');
					this.specialization=response.data.data;
				} catch (error) {
					console.log(error);
				}
			},
			async loadInformations(){
				try {
					const response=await axios.post('/get_informtion');
					this.name = response.data.data.name
					this.email = response.data.data.email
					this.work = response.data.data.work
					this.nameSpecialization = response.data.data.specialization.name
					this.gender = response.data.data.gender
					this.country = response.data.data.country
					this.imageUrl= response.data.data.image_url
					this.description = response.data.data.description
					this.birthday = response.data.data.date_birth
					this.languages = response.data.data.language
					
				} catch (error) {
					console.log(error);
				}
			},
			async update_informtion() {
				try {
					const formData = new FormData();
					formData.append('name', this.name);
					formData.append('email', this.email);
					formData.append('password', this.password);
					formData.append('work', this.work);
					formData.append('id_specialization', this.nameSpecialization);
					formData.append('country', this.country);
					formData.append('gender', this.gender);
					if (this.imageFile) {
						formData.append('image', this.imageFile);
					}
					formData.append('description', this.description);
					formData.append('language', this.languages);
					formData.append('date_birth', this.birthday);
					const token = localStorage.getItem('token');
					const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${token}` 
					}};
					const response = await axios.post('/update_informtion', formData, config);
					this.error=response.data.error;
					this.message=response.data.message;
					
				} catch (error) {
					console.log(error);
				}  
			},
		}
	}
</script>