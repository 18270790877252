<template>
	<div class="flex flex-col sm:flex-row justify-around sm:mt-16 sm:mb-20">
		
		<div class="w-4/5  mx-auto sm:mx-0 mb-12 sm:w-1/3 bg-white shadow-2xl shadow-black flex flex-col ">
			<div class="mx-auto">
				<input type="file" name="image" class="opacity-0 absolute w-20 h-24 ml-2 cursor-pointer">
			<img src="../../assets/admin.png"  class="border-gray w-24 h-24 rounded-full">
			</div>
			<BaseInput type="text" v-model:modalValue="name" label="Fullname"  class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>
			<BaseInput type="text" label="Email" v-model:modalValue="email" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/>
			<!-- <BaseInput type="password" label="Password" v-model:modalValue="Password" class="w-full mx-auto border-b border-blue  focus:border-green  outline-none font-semibold text-green"/> -->
			<div class="mx-auto my-4">
				<button class="py-2 px-6 border-blue border-2 rounded-lg hover:text-green hover:bg-blue transition duration-300 font-semibold text-blue">Save Changes</button>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
import BaseInput from './../../components/BaseInput.vue'
	export default{
		name:'admin profile',
		components:{BaseInput},
		data() {
			return {
				image:'',
				name:'',
				email:'',
			}
		},
		created (){
			this.getInformation()
		},
		methods: {//logout
          async getInformation(){
            try {
              const response=await axios.post('/get_admin');
              this.name=response.data.data.name;
			  this.email=response.data.data.email;
            } catch (error) {
              console.log(error);
            }
          },
        }
	}
</script>